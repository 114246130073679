import * as React from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import ShopAreaList from 'components/screens/ShopAreaSelection/ShopAreaList'
import * as COLORS from 'constants/colors'
import { SAFE_AREA } from 'constants/patterns'
import { useNavigateUtility } from 'lib/hooks'

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  scrollArea: {
    height: '100%',
  },
})

export default function ShopAreaSelection() {
  const { navigate } = useNavigateUtility()

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <ScrollView style={styles.scrollArea}>
        <ShopAreaList onPress={navigate} />
      </ScrollView>
    </View>
  )
}
