import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { setMessagesResult } from 'core/modules/medicationFollowUpMessagesResult'
import { GetMessageApiPayload, MessagesResult } from 'core/domain/medicationFollowUpMessages'
import { store } from 'core/store'

function didCallGetMessagesApi(dispatch: Dispatch, data: MessagesResult) {
  const messageList = store.getState().medicationFollowUpMessages.messageList
  if (messageList.length === 0) {
    dispatch(setMessagesResult(data))
  } else {
    const messages = [...data.messageList, ...messageList]
    dispatch(
      setMessagesResult({
        messageList: messages,
        exclusiveStartKey: data.exclusiveStartKey,
        isCompleted: data.isCompleted,
      }),
    )
  }
}

export function sendGetMessages(payload: GetMessageApiPayload) {
  return async (dispatch: Dispatch) => {
    const query =
      'shopId=' +
      payload.shopId +
      '&perPage=' +
      payload.perPage.toString() +
      (payload.id && '&id=' + payload.id) +
      (payload.sendAt && '&sendAt=' + payload.sendAt)
    // Send Get messages
    await Api.callApi(Api.HttpMethod.get, `messages?${query}`, null, dispatch, didCallGetMessagesApi)
  }
}
