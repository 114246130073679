import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { Prescription } from 'core/domain/prescription'
import { RegisterPrescriptionResult } from 'core/domain/registerPrescriptionResult'
import { setRegisterPrescriptionResult } from 'core/modules/registerPrescriptionResult'

function didCallSendPostPrescriptionApi(dispatch: Dispatch, data: RegisterPrescriptionResult) {
  dispatch(setRegisterPrescriptionResult(data))
}

export function sendPostPrescription(prescription: Prescription) {
  return async (dispatch: Dispatch) => {
    // Send POST prescriptions
    await Api.callApi(
      Api.HttpMethod.post,
      'prescriptions',
      JSON.stringify(prescription),
      dispatch,
      didCallSendPostPrescriptionApi,
    )
  }
}
