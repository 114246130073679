import React from 'react'
import { ViewStyle, StyleSheet, View, Text } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'

/**
 * Interface
 * @message : アナウンスメッセージ
 * @style : スタイル
 */
interface Props {
  message: string
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  announceBarWrapper: {
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.ALERT,
  },
  message: {
    fontSize: FONTS.CAPTION.size,
    lineHeight: FONTS.CAPTION.leading,
    color: '#FFFFFF',
  },
})

export function AnnounceBar(props: Props) {
  const { message } = props
  return (
    <View style={[props.style, styles.announceBarWrapper]}>
      <Text style={styles.message}>{message}</Text>
    </View>
  )
}
