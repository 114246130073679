import React, { useState, useCallback } from 'react'
import { StyleSheet, View, Text, TextInput } from 'react-native'
import { useNavigateUtility, useAppDispatch } from 'lib/hooks'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import * as VALUES from 'constants/values'
import { SAFE_AREA, TEXT_INPUT } from 'constants/patterns'
import { Footer } from 'components/common/atoms/Footer'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { AnnounceLabel } from 'components/common/atoms/AnnounceLabel'
import { setRegisterMedicationFollowUpUserInfo } from 'core/modules/medicationFollowUp'

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  container: {
    height: 'calc(100% - 80px)',
    padding: SPACES.MP2,
  },
  headerSection: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
  },
  inputSection: {
    marginTop: SPACES.MP2,
  },
  labelSection: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
    paddingTop: SPACES.MP2,
    alignItems: 'center',
  },
})

export default function MedicationFollowUpRegisterDetail() {
  const [name, setName] = useState<string>('')
  const [tel, setTel] = useState<string>('')
  const [isNameInputError, setIsNameInputError] = useState<boolean>(false)
  const [isTelInputError, setIsTelInputError] = useState<boolean>(false)
  const { navigate } = useNavigateUtility()
  const dispatch = useAppDispatch()

  // バリデーションチェック
  const validateNameValue = useCallback((value: string): boolean => {
    return value.length > 0
  }, [])

  const validateTelValue = useCallback((value: string): boolean => {
    const regexp = RegExp(`^[0-9]{${VALUES.TEL_INPUT_LIMIT}}$`)
    return regexp.test(value)
  }, [])

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <View style={styles.container}>
        <View>
          <Text style={styles.headerSection}>{'以下の情報を入力してください。'}</Text>
        </View>
        <View style={styles.inputSection}>
          <View style={styles.labelSection}>
            <Text style={TEXT_INPUT.TITLE}>{'名前'}</Text>
            <AnnounceLabel title={'※必須'} />
          </View>
          <TextInput
            style={[TEXT_INPUT.BASE, isNameInputError && TEXT_INPUT.ERROR]}
            value={name}
            placeholder={'例) 山田 太郎'}
            placeholderTextColor={COLORS.PLACEHOLDER}
            keyboardType="default"
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="unless-editing"
            maxLength={32}
            onChangeText={(value: string) => setName(value)}
          />
          {isNameInputError && <AnnounceLabel title={`名前を入力してください`} />}
          <View style={styles.labelSection}>
            <Text style={TEXT_INPUT.TITLE}>{'電話番号'}</Text>
            <AnnounceLabel title={'※必須'} />
          </View>
          <TextInput
            style={[TEXT_INPUT.BASE, isTelInputError && TEXT_INPUT.ERROR]}
            textContentType="telephoneNumber"
            value={tel}
            placeholder={'携帯電話番号（半角ハイフン無し）'}
            placeholderTextColor={COLORS.PLACEHOLDER}
            keyboardType="phone-pad"
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="unless-editing"
            maxLength={VALUES.TEL_INPUT_LIMIT}
            onChangeText={(value: string) => setTel(value)}
          />
          {isTelInputError && <AnnounceLabel title={`半角数字の${VALUES.TEL_INPUT_LIMIT}桁で入力してください`} />}
        </View>
      </View>
      <Footer>
        <PrimaryButton
          label={'送信する'}
          onPress={() => {
            const nameValidateResult = validateNameValue(name)
            setIsNameInputError(!nameValidateResult)
            const telValidateResult = validateTelValue(tel)
            setIsTelInputError(!telValidateResult)
            if (!nameValidateResult || !telValidateResult) return
            dispatch(setRegisterMedicationFollowUpUserInfo({ name: name, tel: tel }))
            navigate('/medication-follow-up-register/confirm')
          }}
        />
      </Footer>
    </View>
  )
}
