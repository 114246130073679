import React from 'react'
import { StyleSheet, Image, View, Text } from 'react-native'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import invalid from 'assets/images/invalid.svg'
import ModalStyles from './InvalidLineVersionModal.module.css'
import * as SPACES from 'constants/spaces'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'

const styles = StyleSheet.create({
  container: {
    padding: SPACES.MP2,
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
    height: '100%',
    width: '100%',
  },
  content: {
    paddingVertical: SPACES.MP2,
  },
  iconWrapper: {
    padding: SPACES.MP4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 72,
    height: 72,
  },
  messageWrapper: {
    paddingBottom: SPACES.MP1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    color: COLORS.ALERT,
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  descriptionWrapper: {
    paddingBottom: SPACES.MP3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    textAlign: 'center',
  },
  primaryButtonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryButton: {
    width: '100%',
  },
})

/**
 * Interface
 * @isOpened : ダイアログ表示
 * @version : LINEバージョン
 * @onDonePress : 決定系ボタンのタップイベント
 */
interface Props {
  isOpened: boolean
  version: string
  onPressDone: () => void
}

export default function ConfirmModal(props: Props) {
  const { isOpened, version } = props
  const message = `この機能はLINEバージョンが${version}以上で有効です。`
  const description = `お持ちのLINEを${version}以上にバージョンアップしてからお使いください。`

  if (!isOpened) {
    return null
  }

  return (
    <div className={ModalStyles.modalContainer}>
      <div className={ModalStyles.modalWrapper}>
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.iconWrapper}>
              <Image style={styles.icon} source={{ uri: invalid }} />
            </View>
            <View style={styles.messageWrapper}>
              <Text style={styles.message}>{message}</Text>
            </View>
            <View style={styles.descriptionWrapper}>
              <Text style={styles.description}>{description}</Text>
            </View>
            <View style={styles.primaryButtonWrapper}>
              <PrimaryButton style={styles.primaryButton} label="OK" onPress={props.onPressDone} />
            </View>
          </View>
        </View>
      </div>
    </div>
  )
}
