import React from 'react'
import { Linking, ViewStyle, StyleSheet, Image, View, Text } from 'react-native'
import * as COLORS from 'constants/colors'
import telIcon from 'assets/images/tel.svg'
import * as FONTS from 'constants/fonts'

import { liff } from '@line/liff'

/**
 * Interface
 * @phoneNumber : 電話番号
 * @style : スタイル
 */
interface Props {
  phoneNumber: string
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  phoneNumberWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  phoneNumber: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.PRIMARY,
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: 16,
  },
})

export function TelLabel(props: Props) {
  const { phoneNumber } = props

  // iOS と Android に対応
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const dialCall = (phoneNumber: string) => {
    let schema = 'tel:'
    if (liff.getOS() === 'ios') schema = 'telprompt:'
    const telUrl = `${schema}${phoneNumber}`
    Linking.openURL(telUrl)
  }

  return (
    <View style={[props.style, styles.phoneNumberWrapper]}>
      <Image style={styles.icon} source={{ uri: telIcon }} />
      <Text
        style={styles.phoneNumber}
        onPress={() => {
          dialCall(phoneNumber)
        }}>
        {phoneNumber}
      </Text>
    </View>
  )
}
