import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: FONTS.TITLE.size,
    color: COLORS.TEXT,
    fontWeight: 'bold',
  },
  countLabel: {
    color: COLORS.SECONDARY,
    marginLeft: SPACES.MP1,
    marginRight: SPACES.MP1,
  },
})

/**
 * Interface
 * @label : 件数のラベル
 * @label : 件数
 * @label : 件数の単位
 */
interface Props {
  label: string
  count: number
  unit: string
}

export default function CountLabel(props: Props) {
  const count = useMemo(() => {
    // 件数が 0 の場合は、「-」を表示する
    return props.count > 0 ? props.count : '0'
  }, [props.count])

  return (
    <View style={styles.background}>
      <Text style={styles.label}>{props.label}</Text>
      <Text style={[styles.label, styles.countLabel]}>{count}</Text>
      <Text style={styles.label}>{props.unit}</Text>
    </View>
  )
}
