import { PrescriptionPeriod } from 'core/domain/prescriptionPeriod'

/**
 * 処方箋の有効期限
 */
const initialState: PrescriptionPeriod = { isValidPeriod: false }

export function createInitialState(): PrescriptionPeriod {
  return initialState
}

export const SET_PRESCRIPTION_PERIOD = 'prescriptionPeriod/set' as const
export const CLEAR_PRESCRIPTION_PERIOD = 'prescriptionPeriod/clear' as const

export function setPrescriptionPeriod(prescriptionPeriod: PrescriptionPeriod) {
  return {
    type: SET_PRESCRIPTION_PERIOD,
    payload: {
      prescriptionPeriod,
    },
  }
}

export function clearPrescriptionPeriod() {
  return {
    type: CLEAR_PRESCRIPTION_PERIOD,
  }
}

type SetPrescriptionPeriodAction = ReturnType<typeof setPrescriptionPeriod>
type ClearPrescriptionPeriodAction = ReturnType<typeof clearPrescriptionPeriod>
type Action = SetPrescriptionPeriodAction | ClearPrescriptionPeriodAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRESCRIPTION_PERIOD:
      return action.payload.prescriptionPeriod
    case CLEAR_PRESCRIPTION_PERIOD:
      return initialState
    default:
      return state
  }
}
