// Color parette from Design Guidelines
// https://github.com/PROJECT-TOMODS/tomods-line-design/wiki/Design

const CPC = '#204359' // Tomod's CPC コーポレートカラー タイトルに使用
const PRIMARY = '#356e92' // Primary +1 メインカラー ボタンなど
const SECONDARY = '#5397c1' // Primary +2 サブカラー
const ALERT = '#C2492C' // 警告
const TEXT = '#544C30' // 文章
const ANNOTATION = '#A69762' // 注釈
const BORDER = '#EEEBE1' // ラインやボーダー
const PLACEHOLDER = '#BEB38D' // プレイスホルダー
const BACKGROUND_PRIMARY = '#f5f5f5' // 背景色
const BACKGROUND_SECONDARY = '#F9F8F5' // 背景色_2
const DISABLED = 'f0f0f0' //　テキストインプットの無効化の際の背景色

const STATUS_NEW = `#D28F7F` // 処方せんのステータス 確認中
const STATUS_DISPENSING = `#D1905C` // 処方せんのステータス 調剤中
const STATUS_DISPENSED = `#88A460` // 処方せんのステータス 調剤完了
const STATUS_FOLLOWUP = `#A75DB9` // 処方せんのステータス 服薬フォロー中
const STATUS_COMPLETED = `#5D4CC4` // 処方せんのステータス 完了
const STATUS_CANCELED = `#7F8C8D` // 処方せんのステータス キャンセル
const STATUS_WARNING = `#C4B44C` // 処方せんのステータス 期限間近

export {
  CPC,
  PRIMARY,
  SECONDARY,
  ALERT,
  TEXT,
  ANNOTATION,
  BORDER,
  PLACEHOLDER,
  BACKGROUND_PRIMARY,
  BACKGROUND_SECONDARY,
  DISABLED,
  STATUS_NEW,
  STATUS_DISPENSING,
  STATUS_DISPENSED,
  STATUS_FOLLOWUP,
  STATUS_COMPLETED,
  STATUS_CANCELED,
  STATUS_WARNING,
}
