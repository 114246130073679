import React, { useEffect, useState } from 'react'
import { Usecases } from 'core'
import { clearShops } from 'core/modules/shops'
import ShopSearch from 'components/screens/ShopSearch'
import { getGeolocation } from 'lib/geolocation'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export interface ErrorInfo {
  offGPS: boolean | undefined
  message: string | null
}

export default function ShopSearchNearContainer() {
  const dispatch = useAppDispatch()
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>({ offGPS: undefined, message: null })

  useEffect(() => {
    // 処方せん詳細情報クリア
    dispatch(Usecases.clearShopDetail.callClearShopDetail())

    getGeolocation(
      dispatch,
      (latitude: number, longitude: number) => {
        dispatch(Usecases.getShops.sendGetShopsByNear(latitude, longitude))
      },
      (error: GeolocationPositionError) => {
        if (error.code === 1) {
          setErrorInfo({ offGPS: true, message: 'GPS設定がオフになっています。設定を確認してください。' })
        }
      },
    )
    return () => {
      dispatch(clearShops())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = {
    clearErrorInfo: () => {
      setErrorInfo({ offGPS: undefined, message: null })
    },
  }

  const shops = useAppSelector(state => state.shops)

  return (
    <ShopSearch
      shops={shops}
      emptyText={'お近くの薬局が見つかりませんでした'}
      errorInfo={errorInfo}
      actions={actions}
    />
  )
}
