import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { ShopInfo } from 'core/domain/shopInfo'
import { setShopDetail } from 'core/modules/shopDetail'

function didCallGetShopApi(dispatch: Dispatch, data: ShopInfo) {
  dispatch(setShopDetail(data))
}

export function sendGetShop(shopId: string) {
  return async function (dispatch: Dispatch) {
    // Send GET /app/shops/{shopId}
    await Api.callApi(Api.HttpMethod.get, `shops/${shopId}`, null, dispatch, didCallGetShopApi)
  }
}
