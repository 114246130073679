import React, { useMemo } from 'react'
import { StyleSheet, TouchableOpacity, Image, Text, View } from 'react-native'
import compass from 'assets/images/compass.svg'
import noimage from 'assets/images/shop_noimage.svg'
import { ShopInfo } from 'core/domain/shopInfo'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'
import { useDistance } from 'lib/hooks'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    height: 120,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
  },
  thumbnail: {
    width: 156,
    height: '100%',
    padding: SPACES.MP2,
  },
  shopImage: {
    width: 124,
    height: 88,
  },
  shopOverview: {
    width: 'calc(100% - 156px)',
    height: '100%',
    justifyContent: 'center',
    paddingRight: SPACES.MP2,
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: SPACES.MP1,
  },
  locationImage: {
    width: 14,
    height: 14,
  },
  baseLabel: {
    fontSize: FONTS.CAPTION.size,
    color: COLORS.TEXT,
    lineHeight: FONTS.CAPTION.leading,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  locationLabel: {
    marginLeft: 3,
    lineHeight: SPACES.MP2,
  },
  shopNameLabel: {
    fontSize: FONTS.BODY.size,
    fontWeight: 'bold',
    marginBottom: SPACES.MP1,
    lineHeight: FONTS.BODY.leading,
  },
  shopAddressLabel: {
    marginBottom: 2,
  },
})

/**
 * Interface
 * @shop 店舗情報
 * @onPress タップイベント
 */
interface Props {
  shop: ShopInfo
  onPress?: (path: string) => void
}

export default function ShopListItem(props: Props) {
  const { shop } = props

  const { distanceLabel } = useDistance(shop.distance)

  const distance = useMemo(() => {
    if (distanceLabel) {
      return (
        <View style={styles.location}>
          <Image style={styles.locationImage} source={{ uri: compass }} />
          <Text style={[styles.baseLabel, styles.locationLabel]}>{distanceLabel}</Text>
        </View>
      )
    }

    return null
  }, [distanceLabel])

  return (
    <TouchableOpacity
      style={styles.background}
      onPress={() => props.onPress && props.onPress(`/shop-detail/${shop.id}`)}>
      <View style={styles.thumbnail}>
        <Image style={styles.shopImage} source={{ uri: shop.imageUrl ? shop.imageUrl : noimage }} />
      </View>
      <View style={styles.shopOverview}>
        {distance}
        <Text style={[styles.baseLabel, styles.shopNameLabel]}>{shop.name}</Text>
        <Text style={[styles.baseLabel, styles.shopAddressLabel]}>{shop.address}</Text>
        <Text style={styles.baseLabel}>{shop.tel}</Text>
      </View>
    </TouchableOpacity>
  )
}
