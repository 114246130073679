import React, { useEffect } from 'react'
import { Usecases } from 'core'
import PrescriptionSend from 'components/screens/PrescriptionSend'
import { useAppDispatch, useAppSelector, useNavigateUtility } from 'lib/hooks'

export default function PrescriptionSendContainer() {
  const dispatch = useAppDispatch()
  const { navigate } = useNavigateUtility()

  const shop = useAppSelector(state => state.shopDetail)
  const registerPrescriptionResult = useAppSelector(state => state.registerPrescriptionResult)

  useEffect(() => {
    // 店舗詳細情報が無い場合は、トップページに遷移する
    if (!shop) {
      navigate('/')
      return
    }
    dispatch(Usecases.getPrescripitonLatest.sendGetPrescriptionLatest())
    return () => {
      dispatch(Usecases.clearPrescriptionLatest.callClearPrescriptionLatest())
      dispatch(Usecases.clearPrescriptionPeriod.callClearPrescriptionPeriod())
      dispatch(Usecases.clearRegisterPrescriptionResult.callClearRegisterPrescriptionResult())
      dispatch(Usecases.clearShopDetail.callClearShopDetail())
    }
  }, [dispatch, navigate, shop])

  return <PrescriptionSend registerPrescriptionResult={registerPrescriptionResult} />
}
