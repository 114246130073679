import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { ShopInfo } from 'core/domain/shopInfo'
import { setShops } from 'core/modules/shops'

type ShopsData = { shops: Array<ShopInfo> }

function didCallGetShopsApi(dispatch: Dispatch, data: ShopsData) {
  dispatch(setShops(data.shops))
}

export function sendGetShopsByArea(areaName: string) {
  return async function (dispatch: Dispatch) {
    // Send GET /app/shops/search-by-area-name
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/search-by-area-name?areaName=${areaName}`,
      null,
      dispatch,
      didCallGetShopsApi,
    )
  }
}

export function sendGetShopsByNear(latitude: number, longitude: number) {
  return async function (dispatch: Dispatch) {
    // Send GET /app/shops/near
    await Api.callApi(
      Api.HttpMethod.get,
      `shops/near?latitude=${latitude}&longitude=${longitude}`,
      null,
      dispatch,
      didCallGetShopsApi,
    )
  }
}
