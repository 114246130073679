// @付きディレクトリのため、相対パスを指定
import { LiffError } from '../../@types/liff'

import { liff } from '@line/liff'

export function init(): Promise<boolean> {
  const LIFF_ID = process.env.REACT_APP_LIFF_ID
  if (!LIFF_ID) {
    throw new Error('LIFF ID is undefined')
  }

  return new Promise((resolve, reject) => {
    liff
      .init({
        liffId: LIFF_ID,
      })
      .then(() => {
        resolve(true)
      })
      .catch((error: LiffError) => {
        reject(error)
      })
  })
}

export function signIn(): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      if (!liff.isLoggedIn()) {
        liff.login()
      }
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export function getAccessToken() {
  return liff.getAccessToken()
}

export async function getProfile() {
  return await liff.getProfile()
}
