import { ShopInfo } from 'core/domain/shopInfo'

const initialState: Array<ShopInfo> = []

export function createInitialState(): Array<ShopInfo> {
  return initialState
}

export const SET_SHOPS = 'shops/set' as const
export const CLEAR_SET_SHOPS = 'shops/clear' as const

export function setShops(shops: Array<ShopInfo>) {
  return {
    type: SET_SHOPS,
    payload: {
      shops,
    },
  }
}

export function clearShops() {
  return {
    type: CLEAR_SET_SHOPS,
  }
}

type SetShopsAction = ReturnType<typeof setShops>
type ClearShopsAction = ReturnType<typeof clearShops>
type Action = SetShopsAction | ClearShopsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_SHOPS:
      return action.payload.shops
    case CLEAR_SET_SHOPS:
      return initialState
    default:
      return state
  }
}
