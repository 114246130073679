import React, { ReactNode } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'
import usageServiceMessageSended from 'assets/images/usage_service_message_sended.png'
import usageServiceMessageReserved from 'assets/images/usage_service_message_reserved.png'
import usageServiceMessageDispensed from 'assets/images/usage_service_message_dispensed.png'

/**
 * Interface
 * @subTitle : 最上部のテキスト下部に表示したいコンポーネントを指定
 */
interface Props {
  subTitle?: ReactNode
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  description: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    letterSpacing: -0.24,
    color: COLORS.TEXT,
    whiteSpace: 'pre-line',
  },
  imageArea: {
    height: 160,
    width: '100%',
    marginTop: SPACES.MP2,
    overflow: 'hidden',
  },
  imageFillServiceMessage: {
    backgroundColor: '#9BC5E5',
  },
})

export function ServiceMessageThumbnails({ subTitle }: Props) {
  return (
    <View>
      <Text style={styles.description}>{'LINEの「LINEミニアプリ お知らせ」に送付完了の通知が届きます。'}</Text>
      {subTitle && subTitle}
      <Image
        style={[styles.imageArea, styles.imageFillServiceMessage]}
        source={{ uri: usageServiceMessageSended }}
        resizeMode={'contain'}
      />
      <Text style={styles.description}>{'薬局で画像を確認し調剤作業に入るとLINEに受付完了メッセージが届きます。'}</Text>
      <Image
        style={[styles.imageArea, styles.imageFillServiceMessage]}
        source={{ uri: usageServiceMessageReserved }}
        resizeMode={'contain'}
      />
      <Text style={styles.description}>{'お受け取り可能になるとLINEにご用意完了のメッセージが届きます。'}</Text>
      <Image
        style={[styles.imageArea, styles.imageFillServiceMessage]}
        source={{ uri: usageServiceMessageDispensed }}
        resizeMode={'contain'}
      />
    </View>
  )
}
