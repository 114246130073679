import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { Prescription } from 'core/domain/prescription'
import { setPrescriptions } from 'core/modules/prescriptions'

type PrescriptionsData = { prescriptions: Array<Prescription> }

function didCallGetPrescriptionsRecentApi(dispatch: Dispatch, data: PrescriptionsData) {
  dispatch(setPrescriptions(data.prescriptions))
}

export function sendGetPrescriptionsRecent() {
  return async (dispatch: Dispatch) => {
    // Send GET /app/prescriptions/recent
    await Api.callApi(Api.HttpMethod.get, 'prescriptions/recent', null, dispatch, didCallGetPrescriptionsRecentApi)
  }
}
