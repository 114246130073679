import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import ShopListItem from 'components/screens/ShopSearch/ShopListItem'
import { EmptyPanel } from 'components/common/atoms/EmptyPanel'
import { ShopInfo } from 'core/domain/shopInfo'
import * as SPACES from 'constants/spaces'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
  },
  listItem: {
    marginBottom: SPACES.MP1,
  },
  empty: {
    height: 132,
  },
})

/**
 * Interface
 * @shops : 店舗一覧
 * @emptyText : 表示する項目が存在しない場合のテキスト
 * @onPress タップイベント
 */
interface Props {
  shops: Array<ShopInfo>
  emptyText: string
  onPress?: (path: string) => void
}

export default function ShopList(props: Props) {
  // データが存在しない場合は0件表示
  if (props.shops.length <= 0) {
    return <EmptyPanel style={styles.empty} text={props.emptyText} />
  }

  return (
    <View style={styles.background}>
      {props.shops.map(shop => (
        <View style={styles.listItem} key={shop.id}>
          <ShopListItem shop={shop} onPress={props.onPress} />
        </View>
      ))}
    </View>
  )
}
