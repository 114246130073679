import React, { ReactNode, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

/**
 * Interface
 * @children : 表示する子要素
 * @size : フッターのサイズを変更
 */
interface Props {
  children: ReactNode
  size?: string
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    height: 80,
    backgroundColor: '#FFF',
    padding: 16,
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  large: {
    height: 116,
  },
})

export function Footer(props: Props) {
  const { size } = props

  const sizeStyle = useMemo(() => {
    switch (size) {
      case 'large':
        return styles.large
      default:
        return
    }
  }, [size])

  return <View style={[styles.background, sizeStyle]}>{props.children}</View>
}
