import { RegisterMedicationFollowUpResult } from 'core/domain/registerMedicationFollowUpResult'

const SET_REGISTER_MEDICATION_FOLLOW_UP_RESULT = 'register-medication-follow-up-result/set' as const
const CLEAR_REGISTER_MEDICATION_FOLLOW_UP_RESULT = 'register-medication-follow-up-result/clear' as const

const initialState: RegisterMedicationFollowUpResult | null = null

function createInitialState(): RegisterMedicationFollowUpResult | null {
  return initialState
}

export function setRegisterMedicationFollowUpResult(result: RegisterMedicationFollowUpResult) {
  return {
    type: SET_REGISTER_MEDICATION_FOLLOW_UP_RESULT,
    payload: result,
  }
}

export function clearRegisterMedicationFollowUpResult() {
  return {
    type: CLEAR_REGISTER_MEDICATION_FOLLOW_UP_RESULT,
  }
}

type SetRegisterMedicationFollowUpResult = ReturnType<typeof setRegisterMedicationFollowUpResult>
type ClearRegisterMedicationFollowUpResult = ReturnType<typeof clearRegisterMedicationFollowUpResult>
type Action = SetRegisterMedicationFollowUpResult | ClearRegisterMedicationFollowUpResult

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_REGISTER_MEDICATION_FOLLOW_UP_RESULT:
      return action.payload
    case CLEAR_REGISTER_MEDICATION_FOLLOW_UP_RESULT:
      return createInitialState()
    default:
      return state
  }
}
