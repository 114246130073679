import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Outlet } from 'react-router-dom'

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
  },
})

export default function MedicationFollowUpRegister() {
  return (
    <View style={styles.background}>
      <Outlet />
    </View>
  )
}
