import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import RouteWrapper from 'components/RouteWrapper'
import ShopSelection from 'containers/ShopSelection'
import Terms from 'components/screens/Terms'
import PrivacyPolicy from 'components/screens/PrivacyPolicy'
import Usage from 'components/screens/Usage'
import PrescriptionSend from 'containers/PrescriptionSend'
import ShopSearchArea from 'containers/ShopSearchArea'
import ShopSearchNear from 'containers/ShopSearchNear'
import ShopAreaSelection from 'containers/ShopAreaSelection'
import ShopDetail from 'containers/ShopDetail'
import PrescriptionSelect from 'containers/PrescriptionSelect'
import PatientDetail from 'containers/PatientDetail'
import PatientConfirm from 'containers/PatientConfirm'
import Registered from 'components/screens/PrescriptionSend/Registered'
import MedicationFollowUpMessage from 'containers/MedicationFollowUpMessageContainer'
import MedicationFollowUpRegister from 'containers/MedicationFollowUpRegister'
import MedicationFollowUpRegisterDetail from 'components/screens/MedicationFollowUpRegister/Detail'
import MedicationFollowUpRegisterConfirm from 'components/screens/MedicationFollowUpRegister/Confirm'
import MedicationFollowUpRegisterDone from 'components/screens/MedicationFollowUpRegister/Done'

const router = createBrowserRouter([
  {
    element: <RouteWrapper title="薬局の詳細" />,
    children: [
      {
        path: '/shop-detail/:shopId/',
        Component: ShopDetail,
      },
    ],
  },
  {
    element: <RouteWrapper title="薬局を選ぶ" />,
    children: [
      {
        path: '/shop-area-selection',
        Component: ShopAreaSelection,
      },
    ],
  },
  {
    element: <RouteWrapper title="薬局を選ぶ" />,
    children: [
      {
        path: '/shop-search/near',
        Component: ShopSearchNear,
      },
    ],
  },
  {
    element: <RouteWrapper title="薬局を選ぶ" />,
    children: [
      {
        path: '/shop-search/area/:areaName',
        Component: ShopSearchArea,
      },
    ],
  },
  {
    element: <RouteWrapper title="プライバシーポリシー" />,
    children: [
      {
        path: '/privacy',
        Component: PrivacyPolicy,
      },
    ],
  },
  {
    element: <RouteWrapper title="利用規約" />,
    children: [
      {
        path: '/terms',
        Component: Terms,
      },
    ],
  },
  {
    element: <RouteWrapper title="このミニアプリの使い方" />,
    children: [
      {
        path: '/usage',
        Component: Usage,
      },
    ],
  },
  {
    path: '/prescription-send',
    Component: PrescriptionSend,
    children: [
      {
        element: <RouteWrapper title="処方せん撮影" />,
        children: [
          {
            path: 'step-1',
            Component: PrescriptionSelect,
          },
        ],
      },
      {
        element: <RouteWrapper title="事前確認" />,
        children: [
          {
            path: 'step-2',
            Component: PatientDetail,
          },
        ],
      },
      {
        element: <RouteWrapper title="最終確認" />,
        children: [
          {
            path: 'step-3',
            Component: PatientConfirm,
          },
        ],
      },
      {
        element: <RouteWrapper title="送信完了" />,
        children: [
          {
            path: 'step-4',
            Component: Registered,
          },
        ],
      },
    ],
  },
  {
    element: <RouteWrapper title="どこでも処方せん送信" />,
    children: [
      {
        path: '/',
        Component: ShopSelection,
      },
    ],
  },
  {
    element: <RouteWrapper title="メッセージ" />,
    children: [
      {
        path: '/medication-follow-up-message/:shopId',
        Component: MedicationFollowUpMessage,
      },
    ],
  },
  {
    path: '/medication-follow-up-register',
    Component: MedicationFollowUpRegister,
    children: [
      {
        element: <RouteWrapper title="服薬フォロー 情報入力画面" />,
        children: [
          {
            path: 'detail',
            Component: MedicationFollowUpRegisterDetail,
          },
        ],
      },
      {
        element: <RouteWrapper title="服薬フォロー 情報入力確認画面" />,
        children: [
          {
            path: 'confirm',
            Component: MedicationFollowUpRegisterConfirm,
          },
        ],
      },
      {
        element: <RouteWrapper title="服薬フォロー 登録完了画面" />,
        children: [
          {
            path: 'done',
            Component: MedicationFollowUpRegisterDone,
          },
        ],
      },
    ],
  },
])

export const Router = () => {
  return <RouterProvider router={router} />
}
