const TEL_INPUT_LIMIT = 11 // 携帯電話番号の文字制限数
const NETWORK_ERROR_MESSAGE = '通信ができません。通信設定及び、電波状況などをご確認ください。'

/* 服薬フォロー関連 */
const MEDICATION_FOLLOW_UP_CONST = {
  GET_MESSAGES_LIMIT: 20, // 一度に取得するメッセージの最大件数
  MESSAGE_TEXT_AREA_LIMIT: 512, // メッセージ入力欄の最大文字数
  NEW_MASSAGE_MIN_HEIGHT: 47,
  NEW_MESSAGE_AUTO_SCROLL_HEIGHT: 100, // メッセージ入力時のオートスクロールハイト
}

export { TEL_INPUT_LIMIT, NETWORK_ERROR_MESSAGE, MEDICATION_FOLLOW_UP_CONST }
