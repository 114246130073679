import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { setMessagesUnreadResult } from 'core/modules/medicationFollowUpMessagesUnreadResult'
import { GetMessagesUnread } from 'core/domain/medicationFollowUpMessages'

function didCallGetMessagesUnreadApi(dispatch: Dispatch, data: GetMessagesUnread) {
  dispatch(setMessagesUnreadResult(data))
}

export function sendGetMessagesUnread() {
  return async (dispatch: Dispatch) => {
    // Send Get messages/unread
    await Api.callApi(Api.HttpMethod.get, `messages/unread`, null, dispatch, didCallGetMessagesUnreadApi)
  }
}
