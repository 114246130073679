import React from 'react'
import { ViewStyle, StyleSheet, Image, View } from 'react-native'
import checkedRadiobutton from 'assets/images/radio_button_checked.svg'
import unCheckedRadioButton from 'assets/images/radio_button_unchecked.svg'

/**
 * Interface
 * @checked : チェック状態
 * @style : スタイル
 */
interface Props {
  checked: boolean | undefined
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  iconWrapper: {
    height: 18,
    width: 18,
  },
  icon: {
    height: 25,
    width: 25,
  },
})

export function RadioButton(props: Props) {
  const { checked = false } = props

  return (
    <View style={[props.style, styles.iconWrapper]}>
      {checked ? (
        <Image style={styles.icon} source={{ uri: checkedRadiobutton }} />
      ) : (
        <Image style={styles.icon} source={{ uri: unCheckedRadioButton }} />
      )}
    </View>
  )
}
