import React from 'react'
import { ViewStyle, StyleSheet, Image, TouchableOpacity } from 'react-native'

/**
 * Interface
 * @iconResource : アイコン表示
 * @disabled : ボタンの非活性状態
 * @styles : スタイル
 * @onPress : タップイベント
 */
interface Props {
  iconResource: string
  disabled?: boolean
  style?: ViewStyle
  onPress?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  button: {
    height: 96,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    /** iOS 10 にて画像が角丸にならないため指定 */
    overflow: 'hidden',
  },
  icon: {
    height: '100%',
    width: '100%',
  },
})

export function ImageButton(props: Props) {
  const { iconResource, disabled } = props

  return (
    <TouchableOpacity style={[styles.button, props.style]} disabled={disabled} onPress={props.onPress}>
      <Image style={styles.icon} source={{ uri: iconResource }} resizeMode={'contain'} />
    </TouchableOpacity>
  )
}
