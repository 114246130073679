import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import ShopAreaListItem from 'components/screens/ShopAreaSelection/ShopAreaListItem'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
  },
})

/**
 * 店舗のエリア名一覧
 */
const shopAreaNames: Array<string> = [
  '【東京都】千代田区・中央区・港区',
  '【東京都】渋谷区・新宿区・文京区・豊島区',
  '【東京都】台東区・墨田区・江東区・荒川区・足立区・葛飾区・江戸川区',
  '【東京都】品川区・目黒区・大田区',
  '【東京都】世田谷区・中野区・杉並区・練馬区',
  '【東京都】北区・板橋区',
  '【東京都】23区以外',
  '【神奈川県】川崎市',
  '【神奈川県】横浜市',
  '【神奈川県】川崎市・横浜市以外',
  '【埼玉県・千葉県】',
  '【宮城県】',
  '【兵庫県】',
]

/**
 * Interface
 * @onPress タップイベント
 */
interface Props {
  onPress?: (path: string) => void
}

export default function ShopAreaList(props: Props) {
  return (
    <View style={styles.background}>
      {shopAreaNames.map(areaName => (
        <View key={areaName}>
          <ShopAreaListItem areaName={areaName} onPress={props.onPress} />
        </View>
      ))}
    </View>
  )
}
