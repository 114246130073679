import * as React from 'react'
import { StyleSheet, ScrollView, Text, View, TouchableOpacity } from 'react-native'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import { ImageButton } from 'components/common/atoms/ImageButton'
import medicIcon from 'assets/images/medic.svg'
import megaphoneIcon from 'assets/images/megaphone.svg'
import clockBlue from 'assets/images/clock_blue.svg'
import nearshop from 'assets/images/nearshop.svg'
import usage from 'assets/images/usage.png'
import area from 'assets/images/area.svg'
import PrescriptionList from 'components/screens/ShopSelection/PrescriptionList'
import { Prescription } from 'core/domain/prescription'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { Information } from 'core/domain/information'
import { useDate, useNavigateUtility } from 'lib/hooks'
import chevronRight from 'assets/images/mdi_chevron-right.png'

import HomeAnnounce from './HomeAnnounce'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  scrollArea: {
    paddingVertical: SPACES.MP2,
  },
  section: {
    marginBottom: SPACES.MP2,
  },
  sectionHistories: {
    marginHorizontal: SPACES.MP1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subSection: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
    marginBottom: SPACES.MP2,
  },
  sectionSupplement: {
    fontSize: FONTS.CAPTION.size,
    color: COLORS.TEXT,
  },
  informationContainer: {
    width: '100%',
    padding: SPACES.MP2,
  },
  information: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
    paddingTop: SPACES.MP2,
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  nodeInformationContainer: {
    width: '100%',
    padding: SPACES.MP2,
  },
  noteInformation: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
    paddingTop: SPACES.MP2,
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  noteSection: {
    fontSize: FONTS.FOOTNOTE.size,
    color: COLORS.TEXT,
    backgroundColor: '#fff',
    marginBottom: SPACES.MP2,
  },
  lastInformation: {
    width: '100%',
    padding: SPACES.MP2,
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  informationDate: {
    color: '#544C30',
  },
  homeAnnounce: {
    marginTop: SPACES.MP2,
    width: '100%',
    paddingHorizontal: SPACES.MP2,
  },
  pharmacySelection: {
    marginTop: SPACES.MP2,
    width: '100%',
    paddingHorizontal: SPACES.MP2,
  },
  pharmacySearchButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pharmacySearchButton: {
    width: 'calc(50% - 4px)',
    textAlign: 'center',
  },
  useHistories: {
    marginTop: SPACES.MP2,
    paddingHorizontal: SPACES.MP1,
  },
  beginnersGuides: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
  },
  beginnersGuidesButton: {
    backgroundColor: COLORS.SECONDARY,
  },
  termsButtons: {
    marginTop: SPACES.MP1,
    marginBottom: SPACES.MP4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  termsButton: {
    width: 'calc(50% - 4px)',
  },
  header: {
    width: '100%',
    height: 28,
    zIndex: 999,
  },
  unreadMessageBlock: {
    width: '100%',
    height: '100%',
    backgroundColor: '#C2492C',
    flexDirection: 'row',
  },
  unreadMessageBox: {
    width: '90%',
    padding: 5,
    alignItems: 'center',
  },
  unreadMessageIconBox: {
    width: '10%',
    padding: 5,
    alignItems: 'center',
  },
  unreadMessageText: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',
  },
  chevronRight: {
    height: '100%',
  },
})

/**
 * Interface
 * @prescriptions : 処方せん一覧
 */
interface Props {
  prescriptions: Array<Prescription>
  information: Information[] | undefined
  messagesUnreadShopIds: string[] | undefined
}

export default function ShopSelection({ information, prescriptions, messagesUnreadShopIds }: Props) {
  const { navigate } = useNavigateUtility()
  const { formatDateSneakCase } = useDate('')
  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      {messagesUnreadShopIds && (
        <TouchableOpacity
          style={styles.header}
          onPress={() => navigate('/medication-follow-up-message/' + messagesUnreadShopIds[0])}>
          <View style={styles.unreadMessageBlock}>
            <View style={styles.unreadMessageBox}>
              <Text style={styles.unreadMessageText}>{'未読のメッセージがあります'}</Text>
            </View>
            <View style={styles.unreadMessageIconBox}>
              <img style={styles.chevronRight} src={chevronRight} />
            </View>
          </View>
        </TouchableOpacity>
      )}
      <ScrollView style={styles.scrollArea}>
        {information && information.length > 0 && (
          <View style={styles.informationContainer}>
            <View style={styles.section}>
              <CommonTitle iconResource={megaphoneIcon} title={'お知らせ'} />
            </View>
            {information.map((n, i) => (
              <View
                key={n.content}
                style={information.length - i - 1 === 0 ? styles.lastInformation : styles.information}>
                <Text style={styles.informationDate}>{formatDateSneakCase(n.startAt)}</Text>
                <div dangerouslySetInnerHTML={{ __html: n.content }} />
              </View>
            ))}
          </View>
        )}
        <View style={styles.beginnersGuides}>
          <ImageButton
            iconResource={usage}
            style={styles.beginnersGuidesButton}
            onPress={() => {
              navigate('/usage')
            }}
          />
        </View>
        <View style={styles.nodeInformationContainer}>
          <View style={styles.noteInformation}>
            <Text style={styles.noteSection}>
              {`このアプリは一部の端末や古いバージョンのアプリでは正常に動作しない場合がございます。最新のバージョンをご利用のうえ本アプリの動作確認を行ってください。`}
            </Text>
          </View>
        </View>
        <View style={styles.pharmacySelection}>
          <View style={styles.section}>
            <CommonTitle iconResource={medicIcon} title={'店舗を選ぶ'} />
          </View>
          <Text style={styles.subSection}>お薬を受け取りたい薬局を選択してください</Text>
          <View style={styles.pharmacySearchButtons}>
            <PrimaryButton
              style={styles.pharmacySearchButton}
              imageResource={nearshop}
              imageSize={'large'}
              label={'近くの\n薬局から選ぶ'}
              labelSize={'small'}
              onPress={() => {
                navigate('/shop-search/near')
              }}
            />
            <PrimaryButton
              style={styles.pharmacySearchButton}
              imageResource={area}
              imageSize={'large'}
              label={'エリアから選ぶ'}
              labelSize={'small'}
              onPress={() => {
                navigate('/shop-area-selection')
              }}
            />
          </View>
        </View>
        <View style={styles.useHistories}>
          <View style={[styles.section, styles.sectionHistories]}>
            <CommonTitle iconResource={clockBlue} title={'ご利用履歴'} />
            <Text style={styles.sectionSupplement}>最新10件を表示</Text>
          </View>
          <PrescriptionList prescriptions={prescriptions} onPress={navigate} />
        </View>
        <View style={styles.homeAnnounce}>
          <View>
            <HomeAnnounce />
          </View>
          <View style={styles.termsButtons}>
            <SecondaryButton
              style={styles.termsButton}
              label={'利用規約'}
              size={'small'}
              labelSize={'xSmall'}
              onPress={() => {
                navigate('/terms')
              }}
            />
            <SecondaryButton
              style={styles.termsButton}
              label={'プライバシーポリシー'}
              size={'small'}
              labelSize={'xSmall'}
              onPress={() => {
                navigate('/privacy')
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
