import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import PrescriptionListItem from 'components/screens/ShopSelection/PrescriptionListItem'
import { EmptyPanel } from 'components/common/atoms/EmptyPanel'
import { Prescription } from 'core/domain/prescription'
import * as SPACES from 'constants/spaces'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
  },
  listItem: {
    marginBottom: SPACES.MP1,
  },
  empty: {
    height: 120,
  },
})

/**
 * Interface
 * @prescriptions : 処方せん一覧
 */
interface Props {
  prescriptions: Array<Prescription>
  onPress?: (path: string) => void
}

export default function PrescriptionList(props: Props) {
  // データが存在しない場合は0件表示
  if (props.prescriptions.length <= 0) {
    return <EmptyPanel style={styles.empty} text={'履歴はありません'} />
  }

  return (
    <View style={styles.background}>
      {props.prescriptions.map((prescription, i) => (
        <View style={i < props.prescriptions.length - 1 ? styles.listItem : []} key={prescription.id}>
          <PrescriptionListItem prescription={prescription} onPress={props.onPress} />
        </View>
      ))}
    </View>
  )
}
