import React, { useEffect } from 'react'
import { Params, useParams } from 'react-router-dom'
import { Usecases } from 'core'
import { clearShops } from 'core/modules/shops'
import ShopSearch from 'components/screens/ShopSearch'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

interface ShopSearchAreaRouterType extends Params {
  areaName: string
}

export default function ShopSearchAreaContainer() {
  const { areaName } = useParams<{ areaName: string }>() as ShopSearchAreaRouterType

  const dispatch = useAppDispatch()

  useEffect(() => {
    // 処方せん詳細情報クリア
    dispatch(Usecases.clearShopDetail.callClearShopDetail())

    dispatch(Usecases.getShops.sendGetShopsByArea(areaName))
    return () => {
      dispatch(clearShops())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shops = useAppSelector(state => state.shops)

  return <ShopSearch shops={shops} emptyText={'エリアに該当する薬局が見つかりませんでした'} />
}
