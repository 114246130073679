import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import ModalStyles from 'components/common/Modal/Modal.module.css'
import * as SPACES from 'constants/spaces'
import * as COLORS from 'constants/colors'

const styles = StyleSheet.create({
  modal: {
    height: '100%',
    width: '100%',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    overflow: 'hidden',
  },
  modalFooter: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.BORDER,
    padding: SPACES.MP2,
  },
  primaryButton: {
    width: `calc(50% - ${SPACES.MP1}px)`,
    marginLeft: SPACES.MP1,
  },
  SecondaryButton: {
    width: `calc(50% - ${SPACES.MP1}px)`,
    marginRight: SPACES.MP1,
  },
})

/**
 * Interface
 * @isOpened : ダイアログ表示
 * @doneLabel : 決定系ボタンのラベル
 * @cancelLabel : 中止系ボタンのラベル
 * @isDisabled : 非活性フラグ
 * @forcePortrait : 縦画面のみフラグ(あえて縦画面表示のみとし、はみ出させる表示にする)
 * @children : チルドレン prop
 * @onDonePress : 決定系ボタンのタップイベント
 * @onCancelPress : 中止系ボタンのタップイベント
 */
interface Props {
  isOpened: boolean
  doneLabel: string
  cancelLabel: string
  isDisabled?: boolean
  forcePortrait?: boolean
  children: React.ReactNode
  onPressDone?: () => void
  onPressCancel?: () => void
}

export default function Modal(props: Props) {
  const { isOpened, doneLabel, cancelLabel, isDisabled, forcePortrait = false } = props

  if (!isOpened) {
    return null
  }

  const modalWrapperClassNames = [
    ModalStyles.modalWrapper,
    forcePortrait && ModalStyles.modalWrapperForcePortrait,
  ].join(' ')

  return (
    <div className={ModalStyles.modalContainer}>
      <div className={modalWrapperClassNames}>
        <View style={styles.modal}>
          {props.children}
          <View style={styles.modalFooter}>
            <SecondaryButton
              style={styles.SecondaryButton}
              label={cancelLabel}
              disabled={isDisabled}
              onPress={props.onPressCancel}
            />
            <PrimaryButton
              style={styles.primaryButton}
              label={doneLabel}
              disabled={isDisabled}
              onPress={props.onPressDone}
            />
          </View>
        </View>
      </div>
    </div>
  )
}
