import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { RegisterMedicationFollowUpResult } from 'core/domain/registerMedicationFollowUpResult'
import { setRegisterMedicationFollowUpResult } from 'core/modules/registermedicationFollowUpResult'

function didCallSendPostPrescriptionsMedicationFollowUpApi(dispatch: Dispatch, data: RegisterMedicationFollowUpResult) {
  dispatch(setRegisterMedicationFollowUpResult(data))
}

export function sendPostPrescription(shopId: string, name: string, tel: string) {
  return async (dispatch: Dispatch) => {
    // Send Post prescriptions/medication-follow-up
    await Api.callApi(
      Api.HttpMethod.post,
      'prescriptions/medication-follow-up',
      JSON.stringify({
        shopId: shopId,
        patientName: name,
        tel: tel,
      }),
      dispatch,
      didCallSendPostPrescriptionsMedicationFollowUpApi,
    )
  }
}
