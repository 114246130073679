import { GetMessagesUnread } from 'core/domain/medicationFollowUpMessages'

const initialState: GetMessagesUnread = {
  shopId: undefined,
}

export function createInitialState(): GetMessagesUnread {
  return initialState
}

export const SET_MESSAGES_UNREAD_RESULT = 'messages_unread_result/set' as const
export const CLEAR_MESSAGES_UNREAD_RESULT = 'messages_unread_result/clear' as const

export function setMessagesUnreadResult(shopIds: GetMessagesUnread) {
  return {
    type: SET_MESSAGES_UNREAD_RESULT,
    payload: shopIds,
  }
}

export function clearMessagesUnreadResult() {
  return {
    type: CLEAR_MESSAGES_UNREAD_RESULT,
  }
}

type SetMessagesUnreadResultAction = ReturnType<typeof setMessagesUnreadResult>
type ClearMessagesUnreadResultAction = ReturnType<typeof clearMessagesUnreadResult>
type Action = SetMessagesUnreadResultAction | ClearMessagesUnreadResultAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_MESSAGES_UNREAD_RESULT:
      return action.payload
    case CLEAR_MESSAGES_UNREAD_RESULT:
      return initialState
    default:
      return state
  }
}
