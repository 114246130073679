import * as React from 'react'
import { StyleSheet, TouchableOpacity, Image, Text } from 'react-native'
import arrowToright from 'assets/images/arrow_toright.svg'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    minHeight: 50,
    paddingHorizontal: SPACES.MP2,
    backgroundColor: '#FFF',
    borderBottomWidth: 1,
    borderColor: COLORS.BORDER,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  areaLabel: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
    paddingVertical: SPACES.MP2,
    paddingRight: SPACES.MP2,
  },
  arrow: {
    width: 9,
    height: 15,
  },
})

/**
 * Interface
 * @areaName : エリアの名称
 * @onPress タップイベント
 */
interface Props {
  areaName: string
  onPress?: (path: string) => void
}

export default function ShopAreaListItem(props: Props) {
  return (
    <TouchableOpacity
      style={styles.background}
      onPress={() => {
        if (props.onPress) {
          const encodedAreaName = encodeURIComponent(props.areaName)
          props.onPress(`/shop-search/area/${encodedAreaName}`)
        }
      }}>
      <Text style={styles.areaLabel}>{props.areaName}</Text>
      <Image style={styles.arrow} source={{ uri: arrowToright }} />
    </TouchableOpacity>
  )
}
