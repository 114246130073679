import * as React from 'react'
import { StyleSheet, ScrollView, Text, View } from 'react-native'
import * as FONTS from 'constants/fonts'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { CommonTitle } from 'components/common/atoms/CommonTitle'

/**
 * Styles
 */
const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: SPACES.MP2,
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  section: {
    marginTop: SPACES.MP4,
  },
  subSection: {
    marginTop: SPACES.MP2,
  },
  description: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    letterSpacing: -0.24,
    color: COLORS.TEXT,
    whiteSpace: 'pre-line',
  },
  sectionTitle: {
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    color: COLORS.TEXT,
    fontWeight: 'bold',
  },
})

export default function PrivacyPolicy() {
  return (
    <View style={SAFE_AREA.BASE}>
      <ScrollView style={styles.container}>
        <View>
          <CommonTitle title={'1. 個人情報保護方針'} />
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'株式会社 トモズ個人情報保護方針'}</Text>
            <Text style={styles.description}>
              {
                '株式会杜トモズ（以下「当社」）では、当社が行う業務及び当社もしくはその代理人が運営するウェブサイトでお客様からお預かりした氏名、住所、 電話番号等の個人を識別できる情報（以下「個人情報」と言います）について、個人情報保護に関する法令、その他の規範を遵守し、大切にお預かりする為、自主的な管理ルールと体制を確立して取り組むこととします。'
              }
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'基本方針'}</Text>
            <Text style={styles.description}>
              {`(1)お客様の個人情報を取り扱う当社の事業内容及び規模を考慮した適切な個人情報の取得、利用及び提供を実施致します。
                (2)個人情報の利用は、利用目的の達成のために必要な範囲に限り、お客様の権利を損なわないように、十分配慮して行います。
                (3)個人情報の取り扱いに関する法令、国が定める指針その他の規範を遵守致します。
                (4)個人情報を管理する際は、管理責任者を置き、適切な管理を行うとともに、外部への流出防止に努めます。また、外部からの不正アクセス、個人情報の滅失、き損、改ざん及び漏洩等の危険防止に対して、適正かつ合理的なレベルの安全対策などの未然防止に万全を期すとともに、事故発生のリスクに対して速やかに是正措置を講じます。
                (5)個人情報の取り扱いは、利用目的の達成のために必要な範囲に限り、お客様の権利を損なわないように、十分配慮して行います。また、当社は、お客様ご自身の同意なしに、業務委託先以外の第三者に開示・提供いたしません。
                (6)個人情報の取り扱いに関する苦情及び相談については、苦情相談窓口責任者を置き、適切に対応致します。
                (7)実践遵守計画（個人情報マネジメントシステム）は、今後の情勢変化に沿って、継続的に見直し、改善します。
                (8)個人情報保護を損なう行為については、毅然たる態度で臨みます。
                `}
            </Text>
            <View>
              <Text style={styles.description}>
                {`株式会社 トモズ
                  代表取締役　角谷 真司
                  改定：2023年11月1日
                `}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <CommonTitle title={'2. 個人情報の取扱いについて'} />
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'1.　事業者の名称、住所、代表者名'}</Text>
            <Text style={styles.description}>
              {`株式会社トモズ
                〒113-0024　東京都文京区西片1丁目15番15号　KDX春日ビル
                代表取締役社長 角谷 真司
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'２.　お預かりする個人情報の内容'}</Text>
            <Text style={styles.description}>
              {`この「個人情報の取扱いについて」（以下「取扱い」といいます）において、「個人情報」とは、「個人情報の保護に関する法律」（平成15年5月30日法律第57号。その後の改正等を含みます）に規定されるものであり、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）、または個人識別符号が含まれるものを指すものとします。
                当社がお客様からお預かりする個人情報は、氏名、住所、生年月日、電話番号、メールアドレス、クレジットカード情報（オンラインショップ）、性別、職種および会員が利用するトモズアプリから取得される会員の位置情報等の情報で、このうち1つまたは複数の組み合わせにより会員個人を特定できる情報となります。
                尚、当社への個人情報の提供はあくまで任意のものですが、情報を提供いただけない場合､当社が提供するサービス等が提供できない場合があります。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'３．個人情報の取得'}</Text>
            <Text style={styles.description}>
              {`当社は、適正かつ合法的な方法によって個人情報を取得いたします。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'４．個人情報の利用目的'}</Text>
            <Text style={styles.description}>
              {`お客様情報の利用は、以下の利用目的の範囲内で行います。
                (1)商品・サービスの代金決済
                (2)当サイト内でお客様がご注文された商品の確認や配送
                (3)ポイントカード会員、各種キャンペーンヘの応募等
                (4)各種のご優待、ＤＭのお知らせ
                (5)お客様からご請求のあった資料・情報のご提供
                (6)店頭でのご注文内容の確認、お取り置き・後日お渡し、修理加工および商品等の配送
                (7)イベントやキャンペーン・アンケート・モニター等の募集活動、応募者へのご連絡・説明、資料・情報・サービスの提供および賞品等の配送
                (8)購買動向、商品開発、商圏分析等の調査活動、販売促進活動
                (9)上記(8)における調査活動の結果の、個人を特定できない形での第三者への提供
                (10)お客様等からのお問い合わせに関する対応
                (11)調剤業務
                (12)調剤を行う上で必要な医療機関との連携
                (13)保険請求業務
                (14)薬物療法の発展のために必要な各種業務
                (15)当社の採用選考
                (16)店頭での無線LANの提供およびこれに付随する業務
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'５．個人情報の外部委託'}</Text>
            <Text style={styles.description}>
              {`当社では個人情報の取扱業務（個人情報のデータ入力業務、ポイントカードシステムの管理・運営業務、購買動向、商品開発、商圏分析等の調査業務、販売促進業務、商品・サービスの代金決済、郵便物の発送業務等）を当社が指定した第三者に委託する場合があります。その際には当社は業務委託業者との間で機密保持契約を交わし、定期的にその管理状況を確認するなど個人情報の保護のために必要な措置を講じます。`}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'６．個人情報の第三者提供'}</Text>
            <Text style={styles.description}>
              {`当社では、お客様からお預かりした個人情報は事前に同意を得た場合を除き第三者に提供・開示することはありません。 ただし、次の各号に該当する場合は法令に基づきお客様の個人情報を第三者に提供したり、利用目的の範囲を超えて利用することがあります。
                ① 法令に基づく場合。
                ② 人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合。
                ③ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難である場合。
                ④ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'７．匿名加工情報の公表事項について'}</Text>
            <Text style={styles.description}>
              {`当社は匿名加工情報(個人情報保護法第2条第6項に定める匿名加工情報をいいます。)の作成および提供を行う場合があります。
                (1)作成・提供する匿名加工情報に含まれる個人に関する情報の項目
                   年齢、性別、購入履歴
                (2)匿名加工情報の提供方法
                   適切にアクセス制限・管理を行っている分析システム(アプリケーションサービス)を介して提供。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'８．当サイトにおいて使用する技術など'}</Text>
            <Text style={styles.description}>
              {`(1)クッキーについて
                お客様が再度当サイトをご利用された時により便利にご利用いただくために、当サイトではクッキーという技術を使用しているページがございます。これは、個人を特定することはございません。また、お客様の使用されているブラウザによりクッキーの使用を制限することもできます。
                (2)個体識別番号について
                携帯サイトでは、お客様が再度携帯サイトをご利用されたときにより便利にご利用いただくために、携帯電話における個体識別番号を取得しております。
                (3)統計ツールについて
                当サイトでは、よりよいサイトへ改善していくことを目的として、アクセス状況を把握するために統計ツールを使用しているページがございます。これは、アクセス数をカウントする目的で使用しており個人を特定するものではございません。
                (4)SSLの利用について
                当サイトおよび携帯サイトでは、通信時の情報漏洩を防ぐためにSSL（暗号通信）を利用しております。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'９．お客様情報についてのお問い合わせ'}</Text>
            <Text style={styles.description}>
              {`当社は、お客様情報の開示、訂正、利用停止等および、安全管理措置に関するお問い合わせ窓口を設置し、法令に従って速やかに合理的な範囲で対応いたします。
                (1)オンラインショップでの商品発送先の訂正・削除に関するご相談窓口
                オンラインショップ カスタマーサポート
                電話番号03-6715-8835
                受付時間10:00～17:00（土/日曜日/年末年始を除く）
                (2)ダイレクトメール送付先の訂正・削除およびポイントカードに関するご相談窓口
                お客様相談窓口
                電話番号0120-982-541
                受付時間9:00～18:00(土/日曜日/祝日、年末年始を除く)]
                (3)開示対象個人情報の取扱いに関する苦情申し出先
                お客様相談窓口
                電話番号0120-982-541
                受付時間9:00～18:00(土/日曜日/祝日、年末年始を除く)
                (4)開示等のお求めのお申出先
                上記ダイレクトメール以外の個人情報について、お客様がご本人の個人情報の確認、訂正、利用停止などを希望される場合、下記宛に郵送によりお願い申し上げます。
                送付先:株式会社トモズ　総務人事部
                〒113-0024東京都文京区西片1-15-15　KDX春日ビル
                ※開示等依頼される場合は「個人情報開示等依頼書」をお送りいたしますので、本人確認ができる身分証明書等の写しをご用意ください。
                ※通知に必要な郵送料は個人負担となりますので、「個人情報開示等依頼書」をご送付頂く際に、郵送分の切手を貼った返信用封筒を同封して下さい。
                <ご来社について>
                直接ご来社いただいてのお申出はお受けいたしかねますので、その旨ご了承賜りますようお願い申し上げます。
              `}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'１０．当社の個人情報保護責任者'}</Text>
            <Text style={styles.description}>{`株式会社トモズ　執行役員　総務人事部分掌`}</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'１１．お客様情報の取り扱い方針の変更'}</Text>
            <Text style={styles.description}>
              {`この取扱いは必要に応じて予告なく変更することがございます。予めご了承くださいますようお願い申し上げます。`}
            </Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.sectionTitle}>{'１２．安全管理措置について'}</Text>
            <Text style={styles.description}>
              {`当社は、個人情報及び、匿名加工情報の適切な取扱いのために、次の様な安全管理措置を適切に実施しています。
                (1)基本方針の策定
                   個人データの適正な取扱いを確保するため、「個人情報保護方針」を策定しています。
                (2)個人データの取扱いに係る規律の整備
                   個人データの取扱方法、責任者・担当者及びその任務等について、個人データの取扱規程を策定しています。
                (3)組織的安全管理措置
                   個人情報の取扱いに関する責任者である「個人情報保護責任者」を設置すると共に、個人情報を取扱う従業者の責任と取扱い範囲を明確化し、法や社内規程に違反している事実又は兆候を把握した場合における責任者への報告体制を整備しています。
                   個人情報の取扱い状況については、定期的に点検すると共に、監査を実施しています。
                (4)人的安全管理措置
                   従業者には定期的に、個人情報の取扱いの留意事項に関する教育を実施しています。
                   個人情報についての秘密保持に関する事項を就業規則に記載すると共に、誓約書を締結しています。
                (5)物理的安全管理措置
                   個人情報を取扱う区域において、従業者の入退室管理及び、持込む機器等を制限し、無権限者による個人データの閲覧を防止しています。
                   個人情報を取扱う機器、書類当の盗難又は紛失等を防止するために、移動中を含め、容易に個人情報が判明しない措置を講じています。また、個人情報の廃棄・削除時には、復元不可能な手段を採用しています。
                (6)技術的安全管理措置
                   セキュリティ機器の導入等のアクセス制御により、個人情報を取扱う機器への不正アクセスや不正ソフトウェアから保護しています。
                (7)外的環境の把握
                   当社では現在、個人データを外国において取扱うことが無いことを確認しています。
              `}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.description}>
            {`所管部署：総務人事部
              改定：2023年11月1日
            `}
          </Text>
        </View>
      </ScrollView>
    </View>
  )
}
