import * as React from 'react'
import { StyleSheet, ScrollView, Text, View, Image } from 'react-native'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import usage from 'assets/images/usage.png'
import usageApp from 'assets/images/usage_app.png'
import usagePicture from 'assets/images/usage_picture.png'
import usageShopSelection from 'assets/images/usage_shop_selection.png'
import usageRecent from 'assets/images/usage_recent.png'
import usageForm from 'assets/images/usage_form.png'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { ServiceMessageThumbnails } from 'components/common/molecules/ServiceMessageThumbnails'

/**
 * Styles
 */
const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: SPACES.MP2,
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  section: {
    marginTop: SPACES.MP2,
  },
  subSection: {
    marginTop: SPACES.MP2,
  },
  sectionTitle: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    color: COLORS.TEXT,
    fontWeight: 'bold',
  },
  description: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    letterSpacing: -0.24,
    color: COLORS.TEXT,
    whiteSpace: 'pre-line',
  },
  header: {
    width: '100%',
    backgroundColor: COLORS.SECONDARY,
  },
  banner: {
    height: 105,
    width: '100%',
  },
  imageArea: {
    height: 160,
    width: '100%',
    marginTop: SPACES.MP2,
    overflow: 'hidden',
  },
  imageBorder: {
    borderWidth: 1,
    borderColor: COLORS.BORDER,
  },
  imageFill: {
    backgroundColor: '#6296BD',
  },
})

export default function Usage() {
  return (
    <View style={SAFE_AREA.BASE}>
      <ScrollView>
        <View style={styles.header}>
          <Image style={styles.banner} resizeMode={'contain'} source={{ uri: usage }} />
        </View>
        <View style={styles.container}>
          <View>
            <CommonTitle title={'どこでも処方せん送信とは'} />
            <Text style={styles.description}>
              {`「どこでも処方せん送信」はお近くのトモズなどでお薬を受け取れるLINEミニアプリです。医療機関で受け取った処方せんをスマホで送信するだけでいつでも手短にお薬を受け取ることができます。利用料金はもちろん無料です。（※通信費はかかります。）`}
            </Text>
            <Image style={[styles.imageArea, styles.imageFill]} source={{ uri: usageApp }} resizeMode={'contain'} />
          </View>
          <View style={styles.section}>
            <View>
              <CommonTitle title={'使い方'} />
              <CommonTitle style={styles.sectionTitle} title={'① 薬局を選ぶ'} color={'secondary'} />
              <Text style={styles.description}>
                {`近くの薬局から選ぶ：
                  現在位置から最も近い薬局を選ぶことができます。

                  エリアごとに選ぶ：
                  薬局のあるエリアで絞り込んで選ぶことができます。
                `}
              </Text>
              <Image
                style={[styles.imageArea, styles.imageBorder]}
                source={{ uri: usageShopSelection }}
                resizeMode={'contain'}
              />
              <Text style={styles.description}>
                {'利用した薬局はご利用履歴に表示されます。二度目以降はこちらからも選ぶことが出来ます。'}
              </Text>
              <Image
                style={[styles.imageArea, styles.imageBorder]}
                source={{ uri: usageRecent }}
                resizeMode={'contain'}
              />
            </View>
            <View style={styles.subSection}>
              <CommonTitle title={'② 処方せんを送る'} color={'secondary'} />
              <Text style={styles.description}>
                {`処方せん撮影：
                  処方せんに書かれた日時で有効期限を確認してください。確認できたらチェックをつけてください。
                  処方せんはなるべく明るく平らな場所に置き、全体の文字が入るように撮影してください。
                  すでに撮影しスマホに記録されている処方せん画像があれば選択することもできます。
                  撮影した画像は調整して文字が読み取りやすいように調節してください。
                  最大5枚まで送信することができます。
                `}
              </Text>
              <Image
                style={[styles.imageArea, styles.imageFill]}
                source={{ uri: usagePicture }}
                resizeMode={'contain'}
              />
            </View>
            <View style={styles.subSection}>
              <CommonTitle title={'③ 事前確認'} color={'secondary'} />
              <Text style={styles.description}>
                {`各項目を確認してチェックをつけてください。アレルギーは食品名や医薬品名を直接記入してください。`}
              </Text>
              <Image
                style={[styles.imageArea, styles.imageBorder]}
                source={{ uri: usageForm }}
                resizeMode={'contain'}
              />
            </View>
            <View style={styles.subSection}>
              <CommonTitle title={'④ 送信完了'} color={'secondary'} />
              <ServiceMessageThumbnails />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
