import { Dispatch } from 'redux'
import React, { useEffect, useState } from 'react'
import { Usecases } from 'core'
import ShopSelection from 'components/screens/ShopSelection'
import { Information } from 'core/domain/information'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export default function ShopSelectionContainer() {
  const dispatch = useAppDispatch()
  const { information } = useInformation(dispatch)
  useEffect(() => {
    // 処方せん詳細情報クリア
    dispatch(Usecases.clearShopDetail.callClearShopDetail())

    dispatch(Usecases.getPrescriptions.sendGetPrescriptionsRecent())
    dispatch(Usecases.getMedicationFollowUpMessagesUnread.sendGetMessagesUnread())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prescriptions = useAppSelector(state => state.prescriptions)
  const messagesUnreadShopIds = useAppSelector(state => state.medicationFollowUpMessagesUnread.shopId)

  return (
    <ShopSelection
      prescriptions={prescriptions}
      information={information}
      messagesUnreadShopIds={messagesUnreadShopIds}
    />
  )
}

function useInformation(dispatch: Dispatch) {
  const [information, setInformation] = useState<Information[]>()
  useEffect(() => {
    const fetchInformation = async () => {
      const data = await Usecases.getInformation.getInformation(dispatch)
      if (data) setInformation(data.information)
    }
    fetchInformation()
  }, [dispatch])

  return { information }
}
