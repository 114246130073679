import React, { useMemo } from 'react'
import { ViewStyle, StyleSheet, Image, View, Text } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Interface
 * @title : タイトル
 * @iconResource : アイコン表示
 * @color : タイトルカラー
 * @styles : スタイル
 */
interface Props {
  title: string
  iconResource?: string
  color?: string
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    fontWeight: 'bold',
    color: COLORS.CPC,
  },
  secondaryTitle: {
    color: COLORS.TEXT,
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: SPACES.MP1,
  },
})

export function CommonTitle(props: Props) {
  const { title, iconResource, color } = props

  const otherTypeTitleStyle = useMemo(() => {
    switch (color) {
      case 'secondary':
        return styles.secondaryTitle
      default:
        return
    }
  }, [color])

  return (
    <View style={[props.style, styles.titleWrapper]}>
      {iconResource && <Image style={styles.icon} source={{ uri: iconResource }} />}
      <Text style={[styles.title, otherTypeTitleStyle]}>{title}</Text>
    </View>
  )
}
