import React from 'react'
import { ViewStyle, StyleSheet, Image, View, Text } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Interface
 * @iconResource : アイコン表示
 * @title : タイトル表示
 * @isDone : 完了状態
 * @styles : スタイル
 */
interface Props {
  iconResource: string
  title: string
  isDone?: boolean
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  stepWrapper: {
    width: 78,
    alignItems: 'center',
  },
  title: {
    marginTop: SPACES.MP1,
    fontSize: FONTS.CAPTION.size,
    lineHeight: FONTS.CAPTION.leading,
    color: COLORS.TEXT,
  },
  oval: {
    height: 46,
    width: 46,
    borderRadius: 23,
    backgroundColor: COLORS.SECONDARY,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  done: {
    backgroundColor: '#204359',
  },
  icon: {
    height: 27,
    width: 32,
  },
})

export function Step(props: Props) {
  const { iconResource, title, isDone } = props

  return (
    <View style={styles.stepWrapper}>
      <View style={[styles.oval, isDone && styles.done]}>
        <Image style={styles.icon} source={{ uri: iconResource }} />
      </View>
      <Text style={styles.title}>{title}</Text>
    </View>
  )
}
