import React from 'react'
import { ViewStyle, StyleSheet, Image, View } from 'react-native'
import checkedIcon from 'assets/images/box_on.svg'
import unCheckedIcon from 'assets/images/box_off.svg'

/**
 * Interface
 * @checked : チェック状態
 * @style : スタイル
 */
interface Props {
  checked: boolean
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  iconWrapper: {
    height: 18,
    width: 18,
  },
  icon: {
    height: 18,
    width: 18,
  },
})

export function CheckBox(props: Props) {
  const { checked } = props

  return (
    <View style={[props.style, styles.iconWrapper]}>
      {checked ? (
        <Image style={styles.icon} source={{ uri: checkedIcon }} />
      ) : (
        <Image style={styles.icon} source={{ uri: unCheckedIcon }} />
      )}
    </View>
  )
}
