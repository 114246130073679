import { Prescription } from 'core/domain/prescription'

const initialState: Array<Prescription> = []

export function createInitialState(): Array<Prescription> {
  return initialState
}

export const SET_PRESCRIPTIONS = 'prescriptions/set' as const
export const CLEAR_PRESCRIPTIONS = 'prescriptions/clear' as const

export function setPrescriptions(prescriptions: Array<Prescription>) {
  return {
    type: SET_PRESCRIPTIONS,
    payload: {
      prescriptions,
    },
  }
}

export function clearPrescriptions() {
  return {
    type: CLEAR_PRESCRIPTIONS,
  }
}

type SetPrescriptionsAction = ReturnType<typeof setPrescriptions>
type ClearPrescriptionsAction = ReturnType<typeof clearPrescriptions>
type Action = SetPrescriptionsAction | ClearPrescriptionsAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRESCRIPTIONS:
      return action.payload.prescriptions
    case CLEAR_PRESCRIPTIONS:
      return initialState
    default:
      return state
  }
}
