import React from 'react'
import { ViewStyle, StyleSheet, Image, View } from 'react-native'
import { Step } from './Step'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import step1Icon from 'assets/images/step1.svg'
import step2Icon from 'assets/images/step2.svg'
import step3Icon from 'assets/images/step3.svg'
import bar from 'assets/images/bar.svg'

/**
 * Interface
 * @currentStep : 現在のステップ
 * @styles : スタイル
 */
interface Props {
  currentStep: number
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  stepperWrapper: {
    height: 100,
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.BORDER,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: SPACES.MP2,
  },
  bar: {
    height: 3,
    flexGrow: 1,
    marginBottom: SPACES.MP3,
  },
})

export function Stepper(props: Props) {
  const { currentStep } = props

  return (
    <View style={[props.style, styles.stepperWrapper]}>
      <Step title={'処方せん撮影'} iconResource={step1Icon} isDone={true} />
      <Image style={styles.bar} source={{ uri: bar }} />
      <Step title={'事前確認'} iconResource={step2Icon} isDone={currentStep >= 2} />
      <Image style={styles.bar} source={{ uri: bar }} />
      <Step title={'最終確認'} iconResource={step3Icon} isDone={currentStep >= 3} />
    </View>
  )
}
