import React from 'react'
import { setPrescriptionLatest } from 'core/modules/prescriptionLatest'
import { Prescription } from 'core/domain/prescription'
import PatientDetail from 'components/screens/PrescriptionSend/PatientDetail'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export default function PatientDetailContainer() {
  const dispatch = useAppDispatch()
  const actions = {
    setPrescriptionLatest(prescription: Prescription) {
      dispatch(setPrescriptionLatest(prescription))
    },
  }

  const prescription = useAppSelector(state => state.prescriptionLatest)

  return <PatientDetail prescription={prescription} actions={actions} />
}
