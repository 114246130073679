import React from 'react'
import { ViewStyle, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CheckBox } from 'components/common/atoms/CheckBox'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'

/**
 * Interface
 * @checked : チェック状態
 * @label : チェックアイテムのラベル
 * @style : スタイル
 * @children : チルドレン prop
 * @onPress : タップイベント
 */
interface Props {
  checked: boolean
  label: string
  style?: ViewStyle
  children?: React.ReactNode
  onPress?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  checkItem: {
    height: 60,
    borderWidth: 1,
    borderColor: COLORS.BORDER,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: SPACES.MP2,
    backgroundColor: '#ffffff',
  },
  checkLabel: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export function CheckItem(props: Props) {
  const { checked, label } = props

  return (
    <TouchableOpacity style={[styles.checkItem, props.style]} onPress={props.onPress}>
      <View style={styles.row}>
        <Text style={styles.checkLabel}>{label}</Text>
        {props.children}
      </View>
      <CheckBox checked={checked} />
    </TouchableOpacity>
  )
}
