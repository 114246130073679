// https://github.com/PROJECT-TOMODS/tomods-line-design/wiki/Design

interface FontStyleSet {
  size: number
  leading: number
}

// Headline ヘッダー
const HEADING: FontStyleSet = {
  size: 22,
  leading: 28,
}
// Callout タイトル
const TITLE: FontStyleSet = {
  size: 16,
  leading: 21,
}
// Subhead 本文
const BODY: FontStyleSet = {
  size: 15,
  leading: 20,
}
// Footnote ボタンラベル
const FOOTNOTE: FontStyleSet = {
  size: 13,
  leading: 18,
}
// 画面サイズ縮小によるフォントサイズ調整用のボタンラベル
const SMALL_FOOTNOTE: FontStyleSet = {
  size: 12,
  leading: 18,
}
// mini 注釈
const CAPTION: FontStyleSet = {
  size: 11,
  leading: 16,
}

export { HEADING, TITLE, BODY, FOOTNOTE, SMALL_FOOTNOTE, CAPTION }
