import React, { useEffect } from 'react'
import { Params, useParams } from 'react-router-dom'
import { Usecases } from 'core'
import { useAppDispatch, useAppSelector } from 'lib/hooks'
import MedicationFollowUpMessage from 'components/screens/MedicationFollowUpMessage'

interface ShopDetailRouterTypes extends Params {
  shopId: string
}

export default function MedicationFollowUpMessageContainor() {
  const { shopId } = useParams<{ shopId: string }>() as ShopDetailRouterTypes
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(Usecases.getShopDetail.sendGetShop(shopId))
  }, [dispatch, shopId])

  const shop = useAppSelector(state => state.shopDetail)
  if (!shop) return null

  return <MedicationFollowUpMessage shop={shop} />
}
