import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { MessagesResult, Message, SendMessageApiPayload } from 'core/domain/medicationFollowUpMessages'
import { store } from 'core/store'
import { Options } from 'lib/api'
import { setMessagesResult } from 'core/modules/medicationFollowUpMessagesResult'
import { formatDateTime } from 'utils/dateFormatters'

function didCallSendPostMessagesApi(dispatch: Dispatch, _data: Message, option?: Options<SendMessageApiPayload>) {
  const messages = store.getState().medicationFollowUpMessages
  const message: Message = {
    id: messages.messageList.length + 1,
    isShop: false,
    content: option?.payload?.content ? option?.payload?.content : '',
    sendAt: formatDateTime(new Date()).toString(),
  }
  const messageResult: MessagesResult = {
    messageList: [...messages.messageList, message],
    exclusiveStartKey: messages.exclusiveStartKey,
    isCompleted: messages.isCompleted,
  }
  dispatch(setMessagesResult(messageResult))
}

export function sendPostMessages(payload: SendMessageApiPayload) {
  return async (dispatch: Dispatch) => {
    // Send Post messages
    await Api.callApi(Api.HttpMethod.post, `messages`, JSON.stringify(payload), dispatch, didCallSendPostMessagesApi, {
      payload: payload,
    })
  }
}
