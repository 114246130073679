import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as FONTS from 'constants/fonts'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { CommonTitle } from 'components/common/atoms/CommonTitle'

/**
 * Styles
 */
const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  section: {
    marginTop: SPACES.MP2,
  },
  description: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    letterSpacing: -0.24,
    color: COLORS.TEXT,
    whiteSpace: 'pre-line',
  },
  sectionTitle: {
    marginTop: SPACES.MP2,
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    color: COLORS.TEXT,
    fontWeight: 'bold',
  },
  emphasis: {
    fontWeight: 'bold',
  },
})

export function TermsTextView() {
  return (
    <View style={styles.container}>
      <View>
        <CommonTitle title={'利用規約'} />
        <Text style={styles.description}>
          <Text style={styles.description}>
            {`本規約は、株式会社トモズ（以下「当社」といいます。)が運営・提供するLINEミニアプリ「どこでも処方せん送信」(`}
          </Text>
          <Text style={styles.emphasis}>{'付随するサービスを含み、'}</Text>
          <Text>
            {`以下「本サービス」といいます。)の利用に関する基本的な事項について定めるものです。本サービスを利用するためには、この利用規約（本規約第２条に基づき変更される内容を含み、以下「本規約」といいます。）に同意いただくことが必要となります。本サービスの利用を希望するお客様（以下「お客様」といいます。）におかれましては、本規約をよくお読みいただき、全ての内容に同意のうえ、本サービスをご利用いただくようお願い申し上げます。万一、本規約の内容に同意いただけない場合又は本規約を遵守いただけない場合は、お客様は本サービスをご利用いただくことはできません。なお、本サービスをご利用いただいた時点で、お客様は本規約の内容に同意いただいたものとみなします。`}
          </Text>
        </Text>
      </View>
      <View style={styles.section}>
        <CommonTitle title={'第１章　総則'} />
        <Text style={styles.sectionTitle}>{'第１条（本サービスの内容)'}</Text>
        <Text style={styles.description}>
          <Text style={styles.description}>
            {`本サービスの内容は、以下に定めるものをいいます。
              ① 処方せん画像の送信による当社グループの薬局における調剤の受付
              ② `}
          </Text>
          <Text style={styles.emphasis}>{'電話による'}</Text>
          <Text>{'服薬フォロー'}</Text>
          <Text style={styles.emphasis}>{'（当社が必要と判断するお客様が対象となります。）'}</Text>
          <Text style={styles.description}>
            {`
              ③ その他上記に付随するサービス`}
          </Text>
        </Text>

        <Text style={styles.sectionTitle}>{'第２条（本規約の変更）'}</Text>
        <Text style={styles.description}>
          {`当社は、自らの判断によりいつでも本規約の内容について変更できるものとし、お客様はあらかじめその旨に同意するものとします。当社は、本規約の内容を変更する場合、お客様に対し、その旨及び内容を当社所定の方法により通知又はホームページへの掲載等により告知するものとします。上記に関わらず、当社が本規約の重要な変更であると判断した場合は、お客様に変更内容を事前に通知のうえ、改めて同意を求め、同意いただいたお客様にその後の本サービスの利用を認めることがあります。本規約の変更に伴い、お客様に不利益又は損害が発生した場合、当社に故意又は重過失がある場合を除いて、当社はその一切の責任を負わないものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第３条（本サービスの利用及び利用資格）'}</Text>
        <Text style={styles.description}>
          {`１. お客様は、本規約に従ってご自身の端末機器のLINEアプリ上で、個人利用することを目的として、お客様の費用と責任において本サービスを利用することができます。なお、本サービスの利用に伴う通信料等の費用はお客様の負担となります。
            ２. 本規約に違反した方、本サービスを悪用し、又は悪用する意図のある方及び他のお客様や当社に対して迷惑行為を行う方はご利用いただけません。当社は、お客様がこれらに該当すると判断した場合には、本サービスの利用を停止することがありますが、その場合でも当社はこの判断を行った理由についてはお客様に一切開示しません。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第４条（お客様の義務及び責任）'}</Text>
        <Text style={styles.description}>
          {`1. 本サービスを利用されるお客様には以下の義務が課されます。
            (1) 本サービスを利用されるお客様ご本人が本規約を確認・同意したうえで本サービスを利用すること。
            (2) お客様のアカウントを第三者に譲渡又は利用させないこと。
            (3) 本サービスの提供に際し、合理的に必要な範囲において当社より必要事項の確認等が求められた場合は、適切に対応すること。
            2. お客様は、本サービスの利用により第三者に損害を与えた場合、ご自身の費用と責任でこれを解決し、当社に対し一切迷惑をかけないものとします。また、お客様による本規約の違反や違法行為によって当社が損害を被った場合、当社はお客様に対して当該損害について賠償請求を行うものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第５条（著作権等の権利の帰属）'}</Text>
        <Text style={styles.description}>
          {`本サービスに関する著作権、商標権等を含む知的財産権その他一切の権利は、当社または正当な権利を有する第三者に帰属するものであり、本サービスの提供は、ご利用者様に対し商標権等の利用、その他の権利を許諾するものではありません。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第６条（禁止事項）'}</Text>
        <Text style={styles.description}>
          {`お客様は、本サービスを利用するにあたり、以下の各号に規定する行為をしてはならないものとします。
            ① 国内外の法律、法令に違反又は公序良俗に反する行為、又はその恐れのある行為
            ② 本サービスの運営に支障をきたす行為、又はその恐れのある行為
            ③ 犯罪行為若しくは犯罪的行為に結びつく行為、又はその恐れのある行為
            ④ 虚偽の情報を入力する行為
            ⑤ ID・パスワード等を第三者に貸与、開示するなど不正に利用する行為
            ⑥ 本サービスの全部又は一部を改変・改竄する行為
            ⑦ 本サービスの加工・頒布・貸与・翻訳・譲渡・転載・複製・自動公衆配信、送信可能化その他これに類する行為
            ⑧ お客様の個人利用を超えて、本サービスを商業・営利目的などに利用する行為
            ⑨ 本サービスに組み込まれているセキュリティ機能を害する行為
            ⑩ 本サービスの一部をお客様個人のプログラムに組み込み、又は結合することを目的としたコンパイル等一切の行為
            ⑪ 本サービスを逆コンパイル、逆アセンブル、リバースエンジニアし、その他本サービスのソースコード、ノウハウ等を解析又は分析する行為
            ⑫ 当社若しくはその役員・従業員、取引先企業又は第三者を誹謗中傷するもの、卑俗なもの、差別的表現にあたるもの、第三者の知的財産権その他の権利を侵害するもの、その他違法、不当な情報等を当社に提供する行為
            ⑬ 本サービス又は第三者の情報端末、通信機器等の機能を害するように設計されたコンピューターウイルス等のプログラムを含む情報等を送信する行為
            ⑭ 当社及び第三者に対して不利益若しくは損害を与える行為、又はそのおそれのある行為
            ⑮ 当社又は第三者になりすます等により当社又は第三者の名誉若しくは信用を棄損する行為
            ⑯ 第7条その他本規約に違反する行為
            ⑰ その他当社が不適切と判断する行為
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第７条（表明・確約）'}</Text>
        <Text style={styles.description}>
          {`お客様は、当社に対し、次の各号に掲げる事項を表明・保証するものとします。
            ① 本サービスの利用開始時から利用終了時までのいつの時点においても、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業・団体又はその関係者、総会屋、社会・政治運動標榜ゴロ、特殊知能暴力集団その他の反社会的勢力又はその所属員（以下、「反社会的勢力」という。）に属するものでないこと
            ② 本サービスの利用開始時から利用終了時までのいつの時点においても、自己又は第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に反社会的勢力を利用していると認められる関係を有していないこと
            ③ 本サービスの利用開始時から利用終了時までのいつの時点においても、反社会的勢力に対して資金等を供与し、又は便宜を供与する等の関与をしていると認められる関係を有していないこと
            ④ 本サービスの利用開始時から利用終時までのいつの時点においても、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないこと
            (1) 暴力的な要求行為
            (2) 法的な責任を超えた不当な要求行為
            (3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
            (4) 風説を流布し、偽計を用い又は威力を用いて当社若しくは第三者の信用を毀損し、又は当社若しくは第三者の業務を妨害する行為
            (5) その他前各号に準ずる行為
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第８条（免責事項）'}</Text>
        <Text style={styles.description}>
          {`１. 当社は、明示又は黙示を問わず、本サービスがお客様の特定の目的に適合すること、お客様が期待する性質を有すること及び本サービスのシステムについて、エラー、バグ、不具合、瑕疵又はセキュリティ上の欠陥が存在しないことについて、いかなる保証もいたしません。また、当社から口頭又は書面等により提供されるいかなる情報も、これらに関する当社の責任を生じさせるものではありません。
            ２. 当社は、本サービスの利用に関して、本条第5項に定める場合を除き、本規約においていかなる保証も行わないものとし、お客様に損害が生じた場合であっても、当社は一切の責任を負わないものとします。
            ３. 当社は、以下に定める損害について、当社に故意又は重過失がある場合を除いて、お客様に対して一切の責任を負いません。
            ① 本サービスの利用によりお客様又はその他の第三者に生じた損害
            ② データ及び端末機器等の損壊に伴うお客様又はその他の第三者に生じた損害
            ③ 本サービスを利用されるネットワーク又はクラウドサーバの不具合等により、本サービスが適切に提供されなかったことによるお客様又はその他の第三者に生じた損害
            ④ アカウント情報の漏洩・盗難、他人のアカウントを使用したなりすまし行為等、第三者の不正利用等によるお客様又はその他の第三者に生じた損害
            ⑤ 当社の個人情報の厳重な管理への取り組みにも関わらず、ハッカー等による不正侵入その他当社の責めに帰すことのできない事由により、お客様の個人情報が漏洩したことによる損害
            ４. お客様は、当社が本サービス利用状況を把握するため、Google Analyticsを利用して、アクセスログを収集することを予め承諾するものとします。なお、Google Analyticsは、cookieを使用し、個人を特定する情報を含むことなく、本サービスの利用状況を収集し、収集された情報はGoogle社のプライバシーポリシーに基づき管理されます。Google Analyticsの利用規約及びGoogle社のプライバシーポリシーについては、同社のWebサイトをご覧ください。当社は、Google Analyticsのサービス利用による損害については一切の責任を負わないものとします。
            ５. 本サービスに関して、当社の責めに帰すべき事由によりお客様に損害が生じた場合は、当社はお客様が現実に被った通常かつ直接の損害に限り賠償責任を負うものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第９条（本サービスの変更・中止等）'}</Text>
        <Text style={styles.description}>
          {`１. 当社は、お客様への事前の通知なく、いつでも本サービスの全部又は一部の変更、停止、中止又は終了することができます。当社は、当該変更等によりお客様に対して損害が発生した場合であっても、一切の責任を負わないものとします。
            ２. お客様が本規約に違反した場合、当社はお客様に対し、直ちにお客様による本サービスへのアクセス権限を無効化できるものとします。当社は、本サービスへのアクセス権限無効化によりお客様に損害が発生した場合であっても、一切の責任を負わないものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１０条（分離可能性）'}</Text>
        <Text style={styles.description}>
          {`本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等によって無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１１条（準拠法・合意管轄）'}</Text>
        <Text style={styles.description}>
          {`本規約は日本法によって解釈されるものとし、お客様による本サービスの利用に関連して紛争が生じた場合は、東京簡易裁判所又は東京地方裁判所を第一審の専属的な合意管轄裁判所とします。
          `}
        </Text>
      </View>
      <View style={styles.section}>
        <CommonTitle title={'第2章　個人情報'} />
        <Text style={styles.sectionTitle}>{'第１２条（個人情報の取り扱い）'}</Text>
        <Text style={styles.description}>
          {`１. 当社は、本サービスの運用について必要な範囲においてのみ、適法かつ適正な方法でお客様の個人情報を取得するものとし、その取得する個人情報及び利用目的は本規約に記載のとおりです。
            ２. 当社では、お客様に対して、本サービスの提供、運用、本サービスの向上等を目的としてお客様の個人情報を提供いただいております。お客様は、当社が必要な保護措置を行ったうえで、個人情報を取り扱うことに同意するものとします。
            ３. 本規約における「個人情報」とは、「個人情報の保護に関する法律」（平成15年5月30日法律第57号。その後の改正等を含みます。）に規定されるものであり、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）、又は個人識別符号が含まれるものを指すものとします。本サービスにおいて、当社は、お客様の氏名、生年月日、年齢、電話番号、性別、処方せん、調剤録、薬剤服用歴の記録に関する情報等を取得します。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１３条（個人情報の利用目的）'}</Text>
        <Text style={styles.description}>
          {`１. 本サービスにおいてお客様から個人情報をご提供いただく場合、その情報は以下の目的にのみ利用します。
            ① 薬局における調剤サービスの提供
            ② 医薬品を安全に使用していただくために必要な事項の把握
            (副作用歴、既往歴、アレルギー、体質、併用薬、緊急連絡先等)
            ③ 病院、診療所、薬局、訪問看護ステーション、介護サービス事業者等との必要な連携
            ④ 病院、診療所等からの照会への回答
            ⑤ 家族等への薬に関する説明
            ⑥ 医療保険事務（審査支払期間への調剤報酬明細書の提出、審査支払い期間又は保険者からの照会への回答等）
            ⑦ 薬剤師賠償責任保険等に係る保険会社への相談又は届出等
            ⑧ 調剤サービスや業務の維持・改善のための基礎資料
            ⑨ 当社内で行う症例研究
            ⑩ 当社内で行う薬学生の薬局実務実習
            ⑪ 外部監査機関への情報提供
            ⑫ お客様との契約の履行、アフターサービスの実施
            ⑬ お客様からのお問い合わせ又はご依頼への対応
            ⑭ 当社が提供する製品・商品・サービスに関するご案内
            ⑮ その他、お客様に事前にお知らせし、ご同意いただいた目的
            ２. 前項以外の目的に利用する場合は、個人情報をご提供いただく際に予めその目的を明示のうえ同意いただくものとし、お客様の個人情報をこれらの正当な目的以外に無断で利用することはありません。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１４条（個人情報の適正管理）'}</Text>
        <Text style={styles.description}>
          {`当社は、お客様の個人情報の取り扱いに際しては、個人情報保護法等の関連する法令を遵守したうえで適切な管理を行います。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１５条（個人情報の第三者提供への同意）'}</Text>
        <Text style={styles.description}>
          {`1. 当社は、お客様からご提供いただいた個人情報につき、業務の一部を外部に委託する場合及び以下のいずれかに該当する場合を除き、第三者へ開示又は提供することはありません。
            ① お客様から同意いただいた場合
            ② 統計的なデータ等、本人を識別することができない状態で開示・提供する場合
            ③ 法令に基づき開示・提供を求められた場合
            ④ 人の生命、身体又は財産の保護のために必要な場合であって、お客様のご同意をいただくことが困難である場合
            ⑤ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様のご同意をいただくことが困難である場合
            ⑥ 国又は地方公共団体等が公的な事務を実施するうえで協力する必要がある場合であって、お客様のご同意をいただくことにより当該事務の遂行に支障を及ぼすおそれがある場合
            2. 当社は、お客様の個人情報の処理等について外部委託を行う場合、当該委託先において適切な安全管理が図られるよう、必要かつ適切な監督を実施します。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１６条（個人情報の共同利用）'}</Text>
        <Text style={styles.description}>
          {`１. 当社は、以下に定める共同利用の目的の範囲内において、お客様の個人情報を共同利用することがあります。
            ① 共同で利用される個人情報の項目
            第12条第3項に定める全てのお客様の個人情報
            ② 共同利用者の範囲
            住友商事グループに属する株式会社アロスワン
            ③ 共同利用する目的
            第13条第１項に定める利用目的
            ④ 個人情報の管理について責任を有する者の名称
            株式会社トモズ
            ２. 前項に定める事項に関して変更する場合は、変更する旨及び内容を当社所定の方法により通知又はホームページへの掲載等により告知するものとします。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１７条（個人情報の開示）'}</Text>
        <Text style={styles.description}>
          {`１. お客様は、本条次項以下の方法によって、当社に対して個人情報の開示を求めることができます。
            ２. 当社は、当社が取り扱う個人情報の開示（当該本人が識別される個人情報が存在しない場合、その旨を知らせることも含みます。）をお客様から求められた場合は、お客様に対して、ご本人からの請求であることを確認のうえで、法令に従い遅滞なく、当該個人情報を開示します。開示の請求に際しては、ご本人であることを確認させていただくため、本人確認書類（免許証・パスポート等）の提示をお願いしておりますので、予めご了承ください。なお、開示請求は、当社所定の方法に従って受け付けるものとします。
            ３. 当社は、開示することにより以下のいずれかに該当する場合、その全部又は一部を開示しないことがあります。
            ① 本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
            ② 当社の業務の適正な実施に著しく支障を及ぼす恐れがある場合
            ③ 他の法令に違反することとなる場合
            ４. 当社は、開示を求められた保有個人情報の全部又は一部について開示しない旨の決定をしたときは、お客様に対して当社所定の方法にて遅滞なくその旨を通知します。
            ５. お客様が本条第１項の開示請求を行う場合、当社所定の手数料を頂く場合があります。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１８条（個人情報の訂正等）'}</Text>
        <Text style={styles.description}>
          {`1． お客様は、本条次項以下によって、当社に対してお客様自身が登録した個人情報の訂正、追加又は削除（以下「訂正等」といいます。）を求めることができます。
            2． 当社は、お客様ご本人から、個人情報の内容が事実ではないという理由によって当該個人情報の訂正等を求められた場合、その内容の訂正等に関して他の法令により特別の手続が定められている場合を除き、第13条に定めた利用目的の達成に必要な範囲内において遅滞なく必要な調査を行い、その結果に基づき、当該保有個人情報の内容の訂正等を行います。なお、訂正等の請求は、当社所定の方法に従って受け付けるものとします。
            3． 当社は、前項に基づき求められた保有個人情報の内容の全部又は一部について訂正等を行った時又は訂正等を行わない旨を決定した時は、お客様ご本人に対して遅滞なくその旨（訂正を行ったときはその内容も含みます。）を当社所定の方法にて通知します。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第１９条（個人情報の利用停止等）'}</Text>
        <Text style={styles.description}>
          {`1． 当社は、お客様から、個人情報が本章の規定に違反して取り扱われているという理由又は適正かつ適法ではない方法により取得されたものであるという理由によって、当該個人情報の利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、お客様ご本人からの請求であることを確認のうえで、その求めが客観的かつ合理的に正しいと判断されるときは、その求めに応じて遅滞なく、当該個人情報の利用停止等を行います。なお、利用停止等の請求は、当社所定の方法に従って受け付けるものとします。ただし、当該保有個人情報の利用停止等に多額の費用を要する場合その他利用停止等を行うことが困難な場合、利用停止等の措置に代えて、本人の権利利益を保護するための他の代償的措置を取ることがあります。
            2． 当社は、前項に基づき求められた保有個人情報の全部又は一部についての利用停止等について、利用停止等を行った時又は利用停止等を行わない旨を決定した時は、お客様ご本人に対して遅滞なくその旨を当社所定の方法にて通知します。
          `}
        </Text>

        <Text style={styles.sectionTitle}>{'第２０条（プライバシーポリシーの適用）'}</Text>
        <Text style={styles.description}>
          {`1．お客様の個人情報に関して本章に定めのない事項については、当社のプライバシーポリシー（https://www.tomods.jp/personal_info/）の規定が適用されるものとします。
            2．本章と当社のプライバシーポリシーが抵触する場合、本章が優先して適用されるものとします。
          `}
        </Text>
      </View>
      <View>
        <Text style={styles.description}>
          {`施行日：2021年5月24日
          `}
        </Text>
      </View>
    </View>
  )
}
