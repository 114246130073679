import React from 'react'
import { Usecases } from 'core'
import ConfirmModal from 'components/common/ConfirmModal'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export default function ErrorPopup() {
  const dispatch = useAppDispatch()

  const actions = {
    clearError() {
      dispatch(Usecases.clearError.callClearError())
    },
  }

  const error = useAppSelector(state => state.error)

  return (
    <ConfirmModal isOpened={error !== null} description={error ? error.message : ''} onPressDone={actions.clearError} />
  )
}
