import { Prescription } from 'core/domain/prescription'

const initialState: Prescription | null = null

export function createInitialState(): Prescription | null {
  return initialState
}

export const SET_PRESCRIPTION_LATEST = 'prescription-latest/set' as const
export const CLEAR_PRESCRIPTION_LATEST = 'prescription-latest/clear' as const

export function setPrescriptionLatest(prescription: Prescription) {
  return {
    type: SET_PRESCRIPTION_LATEST,
    payload: {
      prescription,
    },
  }
}

export function clearPrescriptionLatest() {
  return {
    type: CLEAR_PRESCRIPTION_LATEST,
  }
}

type SetPrescriptionLatestAction = ReturnType<typeof setPrescriptionLatest>
type ClearPrescriptionLatestAction = ReturnType<typeof clearPrescriptionLatest>
type Action = SetPrescriptionLatestAction | ClearPrescriptionLatestAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRESCRIPTION_LATEST:
      return action.payload.prescription
    case CLEAR_PRESCRIPTION_LATEST:
      return initialState
    default:
      return state
  }
}
