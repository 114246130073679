import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Store } from 'core'
import { Router } from 'Routes'
import * as Auth from 'core/domain/auth'
import NeedsSignIn from 'components/NeedsSignIn'
import LoadingPanel from 'containers/LoadingPanel'
import ErrorPopup from 'containers/ErrorPopup'

function App() {
  const [signInFinished, setSignInFinished] = useState(false)
  const [signInFailed, setSignInFailed] = useState(false)

  useEffect(() => {
    Auth.init().then((value: boolean) => {
      if (value) {
        Auth.signIn()
          .then(() => {
            setSignInFailed(false)
          })
          .catch(() => {
            setSignInFailed(true)
          })
          .finally(() => {
            setSignInFinished(true)
          })
      }
    })
  }, [])

  if (!signInFinished) {
    return <></>
  } else if (signInFailed) {
    return <NeedsSignIn />
  } else {
    return (
      <Provider store={Store.store}>
        <LoadingPanel />
        <Router />
        <ErrorPopup />
      </Provider>
    )
  }
}

export default App
