import React, { useMemo, useCallback } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import ConfirmModal from 'components/common/ConfirmModal'
import Portal from 'components/common/Portal'
import CountLabel from 'components/screens/ShopSearch/CountLabel'
import ShopList from 'components/screens/ShopSearch/ShopList'
import { ErrorInfo } from 'containers/ShopSearchNear'
import { ShopInfo } from 'core/domain/shopInfo'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { useNavigateUtility } from 'lib/hooks'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
    paddingTop: SPACES.MP2,
  },
  header: {
    height: 32,
    marginTop: SPACES.MP2,
    paddingHorizontal: SPACES.MP2,
  },
  body: {
    height: `calc(100% - 32px - ${SPACES.MP2}px - ${SPACES.MP1}px)`,
    marginTop: SPACES.MP1,
    paddingHorizontal: SPACES.MP1,
  },
  scrollArea: {
    height: '100%',
  },
})

/**
 * Interface
 * @shops : 店舗一覧
 * @emptyText : 表示する項目が存在しない場合のテキスト
 * @errorInfo : エラー情報
 * @actions : アクションズ
 */
interface Props {
  shops: Array<ShopInfo>
  emptyText: string
  errorInfo?: ErrorInfo
  actions?: {
    clearErrorInfo: () => void
  }
}

export default function ShopSearch(props: Props) {
  const { errorInfo, actions } = props
  const { navigate } = useNavigateUtility()

  const isOpened = useMemo(() => errorInfo !== undefined && errorInfo.offGPS === true, [errorInfo])
  const description = useMemo(() => (errorInfo && errorInfo.message ? errorInfo.message : ''), [errorInfo])

  const clearErrorInfo = useCallback(() => {
    if (!actions) return
    actions.clearErrorInfo()
  }, [actions])

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <View style={styles.header}>
        <CountLabel label={'検索結果'} count={props.shops.length} unit={'件'} />
      </View>
      <View style={styles.body}>
        <ScrollView style={styles.scrollArea}>
          <ShopList shops={props.shops} emptyText={props.emptyText} onPress={navigate} />
        </ScrollView>
      </View>
      <Portal targetId={'modal'}>
        <ConfirmModal isOpened={isOpened} description={description} onPressDone={clearErrorInfo} />
      </Portal>
    </View>
  )
}
