import React from 'react'
import { Selectors } from 'core'
import LoadingPanel from 'components/LoadingPanel'
import { useAppSelector } from 'lib/hooks'

export default function LoadingPanelContainer() {
  const needsToShow = useAppSelector(state => Selectors.Network.isCommunicating(state))

  return <LoadingPanel needsToShow={needsToShow} />
}
