import React, { useEffect } from 'react'
import { Params, useParams } from 'react-router-dom'
import { Usecases } from 'core'
import ShopDetail from 'components/screens/ShopDetail'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

interface ShopDetailRouterTypes extends Params {
  shopId: string
}

export default function ShopDetailContainer() {
  const { shopId } = useParams<{ shopId: string }>() as ShopDetailRouterTypes

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(Usecases.getShopDetail.sendGetShop(shopId))
    // クリーンアップで処方せん情報をクリアすると、処方せん送信画面に遷移出来ないため、
    // ここではクリアは行わない
  }, [dispatch, shopId])

  const shop = useAppSelector(state => state.shopDetail)

  return <ShopDetail shop={shop} />
}
