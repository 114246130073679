import React from 'react'
import { StyleSheet, Image, View, Text, ScrollView } from 'react-native'
import { useNavigateUtility } from 'lib/hooks'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import { Footer } from 'components/common/atoms/Footer'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import followUpLineMessageImg from 'assets/images/follow_up_line_message.png'
import followUpMessageImg from 'assets/images/follow_up_message.png'
import followUpMessageSendImg from 'assets/images/follow_up_message_send.png'
import { SAFE_AREA } from 'constants/patterns'

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  container: {
    height: 'calc(100% - 80px)',
    padding: SPACES.MP2,
  },
  headerSection: {
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    color: COLORS.CPC,
    fontWeight: 'bold',
    marginBottom: SPACES.MP2,
  },
  contentText: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
  },
  contentAlert: {
    color: COLORS.ALERT,
  },
  banner: {
    height: 160,
    marginTop: SPACES.MP2,
    marginBottom: SPACES.MP2,
    backgroundColor: '#8CC0DF',
  },
})

export default function MedicationFollowUpRegisterDone() {
  const { navigateReplace } = useNavigateUtility()
  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <ScrollView style={styles.container}>
        <Text style={styles.headerSection}>{'服薬フォロー登録が完了しました'}</Text>
        <Text style={styles.contentText}>{'LINEのServiceメッセージに薬局からのメッセージの通知が届きます。'}</Text>
        <Image style={styles.banner} resizeMode={'contain'} source={{ uri: followUpLineMessageImg }} />
        <Text style={styles.contentText}>{'詳細はこちらをタップするとメッセージ画面に移動します。'}</Text>
        <Image style={styles.banner} resizeMode={'contain'} source={{ uri: followUpMessageImg }} />
        <Text style={styles.contentText}>{'メッセージが確認できたら画面下の入力欄から返信を行います。'}</Text>
        <Image style={styles.banner} resizeMode={'contain'} source={{ uri: followUpMessageSendImg }} />
        <Text style={[styles.contentText, styles.contentAlert]}>{'・メッセージは数回届きます'}</Text>
        <Text style={[styles.contentText, styles.contentAlert]}>
          {'・新しいメッセージはサービスメッセージの通知とミニアプリのトップ画面でも確認できます。'}
        </Text>
      </ScrollView>
      <Footer>
        <SecondaryButton label={'最初の画面に戻る'} onPress={() => navigateReplace('/')} />
      </Footer>
    </View>
  )
}
