import React, { useEffect, useState, useMemo, useRef } from 'react'
import { StyleSheet, View, Text, ScrollView, Image } from 'react-native'
import { Prescription } from 'core/domain/prescription'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { CommonLabel } from 'components/common/atoms/CommonLabel'
import { AnnounceLabel } from 'components/common/atoms/AnnounceLabel'
import { CheckItem } from 'components/common/molecules/CheckItem'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { PreviewImage } from 'components/common/atoms/PreviewImage'
import { Footer } from 'components/common/atoms/Footer'
import { NotificationLabel } from 'components/common/atoms/NotificationLabel'
import Modal from 'components/common/Modal'
import Portal from 'components/common/Portal'
import ViewImage from 'components/common/ViewImage'
import PrescriptionSelectStyles from './PrescriptionSelect.module.css'
import { resizeAndConvertFileToBase64 } from 'utils/dataFormatters'
import cameraIcon from 'assets/images/camera.svg'
import prescriptionOKImage from 'assets/images/prescription_OK_image.png'
import prescriptionOutOfFocusNGImage from 'assets/images/prescription_out_of_focus.png'
import prescriptionCutOffNGImage from 'assets/images/prescription_cut_off.png'
import prescriptionReflectedLightNGImage from 'assets/images/prescription_reflected_light.png'
import alertIcon from 'assets/images/alert.svg'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import { useNavigateUtility } from 'lib/hooks'

import { liff } from '@line/liff'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: 'calc(100% - 100px)',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  prescriptionSection: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
  },
  prescriptionTitle: {
    marginTop: SPACES.MP2,
  },
  prescriptionDescription: {
    marginTop: SPACES.MP2,
  },
  prescriptionAnnounce: {
    marginTop: SPACES.MP1,
  },
  checkItem: {
    marginTop: SPACES.MP2,
  },
  prescriptionShootSection: {
    width: '100%',
    paddingHorizontal: SPACES.MP2,
  },
  prescriptionShootButton: {
    marginTop: SPACES.MP2,
  },
  imagePreviewArea: {
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: COLORS.BORDER,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    marginTop: SPACES.MP2,
    paddingTop: SPACES.MP1,
    paddingLeft: SPACES.MP1,
  },
  noImagePreviewArea: {
    height: 100,
    borderWidth: 1,
    borderColor: COLORS.BORDER,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    marginTop: SPACES.MP2,
  },
  imageWrapper: {
    marginRight: SPACES.MP1,
    marginBottom: SPACES.MP1,
  },
  previewPlaceholder: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
  },
  imageDescription: {
    textAlign: 'center',
    fontSize: FONTS.SMALL_FOOTNOTE.size,
  },
  prescriptionShootTitle: {
    marginTop: SPACES.MP2,
    marginLeft: SPACES.MP2,
    marginRight: SPACES.MP2,
  },
  prescriptionShootDescription: {
    marginTop: SPACES.MP1,
    marginBottom: SPACES.MP2,
    marginLeft: SPACES.MP2,
    marginRight: SPACES.MP2,
  },
  checkItemAnnounce: {
    marginLeft: SPACES.MP1,
  },
  splitContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: SPACES.MP1,
    gap: SPACES.MP1,
  },
  splitItem: {
    flex: 1,
  },
  prescriptionOKImg: {
    marginTop: SPACES.MP1,
    marginBottom: SPACES.MP1,
    width: '100%',
    aspectRatio: 687 / 320,
  },
  prescriptionNGImg: {
    marginTop: SPACES.MP1,
    marginBottom: SPACES.MP1,
    width: '100%',
    aspectRatio: 220 / 280,
  },
  shootDescription: {
    marginTop: SPACES.MP2,
    borderBottomWidth: 2,
    borderColor: COLORS.ALERT,
    borderStyle: 'solid',
  },
  descriptionFrame: {
    marginTop: SPACES.MP3,
    backgroundColor: '#EEEEEE',
  },
})

/**
 * Interface
 * @prescription 処方せん情報
 */
interface Props {
  prescription: Prescription | null
  isValidPeriod: boolean
  actions: {
    setPrescriptionLatest: (prescription: Prescription) => void
    setPrescriptionPeriod: (isValidPeriod: boolean) => void
  }
}

export default function PrescriptionSelect(props: Props) {
  const { prescription, isValidPeriod, actions } = props
  const { navigate } = useNavigateUtility()
  const [imageData, setImageData] = useState<string | null>(null)
  const [isCreatingEditedImage, setIsCreatingEditedImage] = useState(false)
  const inputEl = useRef<HTMLInputElement>({} as HTMLInputElement)
  const clickInputEl = () => inputEl.current.click()
  const [isOpenedViewModal, setIsOpenedViewModal] = useState(false)
  const [viewImagePosition, setViewImagePosition] = useState<number | null>(null)
  const imageLength = useMemo(() => {
    const innerLength = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight
    return (innerLength - 66) / 3
  }, [])

  const images = useMemo(() => {
    if (!prescription) return []
    if (!prescription.images) return []
    return prescription.images
  }, [prescription])

  const isDisabledNextButton = useMemo(() => {
    return images.length === 0 || !isValidPeriod
  }, [images, isValidPeriod])

  useEffect(() => {
    if (!prescription) return
    if (!imageData) return
    setIsCreatingEditedImage(false)
    // 実寸の画像を作成完了時だけ実行
    if (isCreatingEditedImage) return
    // 初期化
    setImageData(null)
    if (images.length >= 5) return
    // Reactのバージョンを上げたことで、無限ループが発生してしまっていたので、重複した画像かどうかをチェック
    // imagesの中に同じ画像があっても意味がないので、重複した画像は追加しない
    if (images.includes(imageData)) return
    const newImages = [...images, imageData]
    actions.setPrescriptionLatest({ ...prescription, images: newImages })
  }, [isCreatingEditedImage, imageData, images, prescription, actions])

  if (!prescription) return <></>

  return (
    <View style={styles.background}>
      <ScrollView>
        <View style={styles.prescriptionSection}>
          <CommonTitle style={styles.prescriptionTitle} title={'処方せんを撮影'} />
          <CommonLabel
            style={styles.prescriptionDescription}
            title={'医療機関でもらった処方せんを撮影して薬局に送信します。2枚以上ある場合は、1枚ずつ撮影してください。'}
          />
          <Image style={styles.prescriptionOKImg} source={{ uri: prescriptionOKImage }} />
          <Text style={styles.imageDescription}>全体が映るように撮影してください</Text>
          <NotificationLabel
            style={styles.shootDescription}
            title={'以下のような撮影内容は承認されません'}
            iconResource={alertIcon}
          />
          <View style={styles.splitContainer}>
            <View style={styles.splitItem}>
              <Image style={styles.prescriptionNGImg} source={{ uri: prescriptionOutOfFocusNGImage }} />
              <Text style={styles.imageDescription}>ピンボケ</Text>
            </View>
            <View style={styles.splitItem}>
              <Image style={styles.prescriptionNGImg} source={{ uri: prescriptionCutOffNGImage }} />
              <Text style={styles.imageDescription}>見切れている</Text>
            </View>
            <View style={styles.splitItem}>
              <Image style={styles.prescriptionNGImg} source={{ uri: prescriptionReflectedLightNGImage }} />
              <Text style={styles.imageDescription}>光が反射している</Text>
            </View>
          </View>
          <View style={styles.descriptionFrame}>
            <CommonTitle style={styles.prescriptionShootTitle} title={'処方せん撮影のヒント'} />
            <CommonLabel
              style={styles.prescriptionShootDescription}
              title={
                '・処方せん画像をタップすると拡大して確認できます。\n・画像が見えにくい場合は削除してもう一度撮影してください。\n・処方せん画像の×ボタンで削除できます。\n・最大5枚まで送信できます。\n'
              }
            />
          </View>
          <AnnounceLabel
            style={styles.prescriptionAnnounce}
            size="middle"
            title={
              'お手元の処方せんの有効期限をご確認ください。有効期限は発行日を含め4日以内となります。処方せん原本はお薬受取時間に期限内にお持ちいただく必要があります。'
            }
          />
        </View>
        <CheckItem
          style={styles.checkItem}
          checked={isValidPeriod}
          label={'処方せんが有効期限内である'}
          onPress={() => {
            actions.setPrescriptionPeriod(!isValidPeriod)
          }}>
          <AnnounceLabel style={styles.checkItemAnnounce} title={'※必須'} />
        </CheckItem>
        <View style={styles.prescriptionShootSection}>
          <View style={styles.prescriptionShootButton}>
            <PrimaryButton
              imageResource={cameraIcon}
              label={'処方せんを撮影する'}
              disabled={!isValidPeriod || images.length >= 5}
              onPress={() => {
                clickInputEl()
              }}
            />
            <input
              ref={inputEl}
              className={PrescriptionSelectStyles.inputFile}
              type={'file'}
              accept={'image/*'}
              capture={liff.getOS() === 'android' ? 'environment' : false}
              onChange={async event => {
                if (event && event.target && event.target.files) {
                  const convertedImage = await resizeAndConvertFileToBase64(event.target.files[0], 'image.*')
                  setImageData(convertedImage)
                  setIsCreatingEditedImage(true)
                  inputEl.current.value = ''
                }
              }}
            />
          </View>
          {images.length > 0 ? (
            <View style={styles.imagePreviewArea}>
              {images.map((image, index) => (
                <View style={styles.imageWrapper} key={index}>
                  <PreviewImage
                    image={image}
                    length={imageLength}
                    onPressImage={() => {
                      setViewImagePosition(index)
                      setIsOpenedViewModal(true)
                    }}
                    onPressDelete={() => {
                      const newImages = images.filter((_, position) => position !== index)
                      actions.setPrescriptionLatest({ ...prescription, images: newImages })
                    }}
                  />
                </View>
              ))}
            </View>
          ) : (
            <View style={styles.noImagePreviewArea}>
              <Text style={styles.previewPlaceholder}>{'処方せん画像が表示されます'}</Text>
            </View>
          )}
        </View>
      </ScrollView>
      <Footer>
        <PrimaryButton
          label="次へ"
          disabled={isDisabledNextButton}
          onPress={() => {
            if (isDisabledNextButton) return
            navigate('/prescription-send/step-2')
          }}
        />
      </Footer>
      <Portal targetId={'modal'}>
        <Modal
          isOpened={isOpenedViewModal}
          doneLabel={'削除する'}
          cancelLabel={'やめる'}
          isDisabled={isCreatingEditedImage}
          forcePortrait={true}
          onPressDone={() => {
            const newImages = images.filter((_, position) => position !== viewImagePosition)
            actions.setPrescriptionLatest({ ...prescription, images: newImages })
            setIsOpenedViewModal(false)
          }}
          onPressCancel={() => {
            setIsOpenedViewModal(false)
          }}>
          <ViewImage dataUrl={viewImagePosition !== null ? images[viewImagePosition] : null} />
        </Modal>
      </Portal>
    </View>
  )
}
