import * as React from 'react'
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native'
import StatusChip from 'components/screens/ShopSelection/StatusChip'
import { Prescription } from 'core/domain/prescription'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'
import { useDate } from 'lib/hooks'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: 111,
    borderRadius: 4,
    padding: SPACES.MP2,
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  body: {
    marginTop: SPACES.MP2,
  },
  bodyTitle: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
  },
})

/**
 * Interface
 * @prescriptions : 処方せん一覧
 */
interface Props {
  prescription: Prescription
  onPress?: (path: string) => void
}

export default function PrescriptionListItem(props: Props) {
  const { prescription } = props

  const { dateTime: acceptedAt } = useDate(prescription.acceptedAt)

  return (
    <TouchableOpacity
      style={styles.background}
      onPress={() => props.onPress && props.onPress(`/shop-detail/${prescription.shopId}`)}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{prescription.shopName}</Text>
        <StatusChip status={prescription.status} isExpireSoon={prescription.isExpireSoon} />
      </View>
      <View style={styles.body}>
        <Text style={styles.bodyTitle}>{`受付番号  ${prescription.acceptedCode}`}</Text>
        <Text style={styles.bodyTitle}>{`受付時間  ${acceptedAt}`}</Text>
      </View>
    </TouchableOpacity>
  )
}
