import { AppDispatch, RootState } from 'core/store'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { TypedUseSelectorHook, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useNavigateUtility = () => {
  const navigateFunction = useNavigate()

  const navigate = useCallback(
    (to: string, state?: unknown) => {
      navigateFunction(to, {
        state,
      })
    },
    [navigateFunction],
  )

  const navigateReplace = useCallback(
    (to: string, state?: unknown) => {
      navigateFunction(to, {
        state,
        replace: true,
      })
    },
    [navigateFunction],
  )

  return {
    navigate,
    navigateReplace,
  }
}

// 日付変換用
export const useDate = (dateStr: string) => {
  const [originalDateStr] = useState(dateStr)

  // 0埋め
  const fillZero = useCallback((num: number): string => `0${num}`.slice(-2), [])

  // YYYY/MM/DD HH:mm:ss 形式
  const dateTime = useMemo(() => {
    const original = originalDateStr
    const d = new Date(original)
    // 変換エラーケア
    if (isNaN(d.getTime())) return original

    const month = fillZero(d.getMonth() + 1)
    const date = fillZero(d.getDate())
    const hours = fillZero(d.getHours())
    const minutes = fillZero(d.getMinutes())
    const seconds = fillZero(d.getSeconds())
    const formattedDateTime = `${d.getFullYear()}/${month}/${date} ${hours}:${minutes}:${seconds}`
    return formattedDateTime
  }, [originalDateStr, fillZero])

  const formatDateSneakCase = (str: string): string => {
    const d = new Date(str)
    if (isNaN(d.getTime())) return originalDateStr
    const month = fillZero(d.getMonth() + 1)
    const date = fillZero(d.getDate())
    return `${d.getFullYear()}-${month}-${date}`
  }

  return { originalDateStr, dateTime, formatDateSneakCase }
}

// 距離処理用
export const useDistance = (distance?: number) => {
  // 数字をカンマ区切りの文字列で返却
  // ex. 1234 → 1,234
  const separateDigit = useCallback((value: number | string) => {
    const valueStr = typeof value === 'number' ? String(value) : value
    return valueStr.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }, [])

  const distanceLabel: string | undefined = useMemo(() => {
    if (distance === undefined) return undefined
    // km 変換
    if (distance >= 1) {
      const distanceSpl = String(distance).split('.')
      // 小数点下一桁切り出し
      const kmDicimal = distanceSpl[1] ? distanceSpl[1].slice(0, 1) : '0'
      const km = separateDigit(distanceSpl[0])
      return `${km}.${kmDicimal}km`
    }
    // m 変換
    const meter = distance * 1000
    const intMeter = parseInt(String(meter), 10)
    return `${intMeter || 1}m`
  }, [distance, separateDigit])

  return { distanceLabel }
}
