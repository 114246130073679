import { RegisterPrescriptionResult } from 'core/domain/registerPrescriptionResult'

const initialState: RegisterPrescriptionResult | null = null

export function createInitialState(): RegisterPrescriptionResult | null {
  return initialState
}

export const SET_REGISTER_PRESCRIPTION_RESULT = 'register-prescription-result/set' as const
export const CLEAR_REGISTER_PRESCRIPTION_RESULT = 'register-prescription-result/clear' as const

export function setRegisterPrescriptionResult(result: RegisterPrescriptionResult) {
  return {
    type: SET_REGISTER_PRESCRIPTION_RESULT,
    payload: result,
  }
}

export function clearRegisterPrescriptionResult() {
  return {
    type: CLEAR_REGISTER_PRESCRIPTION_RESULT,
  }
}

type SetRegisterPrescriptionResultAction = ReturnType<typeof setRegisterPrescriptionResult>
type ClearRegisterPrescriptionResultAction = ReturnType<typeof clearRegisterPrescriptionResult>
type Action = SetRegisterPrescriptionResultAction | ClearRegisterPrescriptionResultAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_REGISTER_PRESCRIPTION_RESULT:
      // 成功時空オブジェクトが返るため、payload を直に引き渡す
      return action.payload
    case CLEAR_REGISTER_PRESCRIPTION_RESULT:
      return initialState
    default:
      return state
  }
}
