import React from 'react'
import { ViewStyle, StyleSheet, Image, TouchableOpacity } from 'react-native'
import closeIcon from 'assets/images/close.svg'
import * as SPACES from 'constants/spaces'

/**
 * Interface
 * @image : プレビュー表示
 * @length : 画像サイズ
 * @style : スタイル
 * @onPressBody : タップイベント
 * @onPressDelete : 削除タップイベント
 */
interface Props {
  image: string
  length?: number
  style?: ViewStyle
  onPressImage?: () => void
  onPressDelete?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  imageWrapper: {
    position: 'relative',
  },
  closeWrapper: {
    position: 'absolute',
    zIndex: 1,
    top: SPACES.MP1,
    right: SPACES.MP1,
  },
  close: {
    height: 30,
    width: 30,
  },
})

export function PreviewImage(props: Props) {
  const { image, length } = props

  return (
    <TouchableOpacity style={[props.style, styles.imageWrapper]} onPress={props.onPressImage}>
      <img src={image} width={length} height={length} />
      <TouchableOpacity style={styles.closeWrapper} onPress={props.onPressDelete}>
        <Image style={styles.close} source={{ uri: closeIcon }} />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}
