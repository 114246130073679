import { View, TouchableOpacity, StyleSheet, Text, ViewStyle } from 'react-native'

import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import { RadioButton } from 'components/common/atoms/RadioButton'

/**
 * Interface
 * @labels : ラジオボタンのそれぞれのラベル
 * @isChecked : チェック状態
 * @style : スタイル
 * @children : チルドレン prop
 * @onPress : タップイベント
 */
interface Props {
  labels: [string, string]
  isChecked: boolean | undefined
  style?: ViewStyle
  children?: React.ReactNode
  onPressFirst?: () => void
  onPressSecond?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  radioItem: {
    marginRight: SPACES.MP2,
  },
  radioButtonLabel: {
    fontSize: FONTS.BODY.size,
    color: COLORS.TEXT,
    alignContent: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  touchableOpacityRow: {
    flexDirection: 'row',
    height: 25,
  },
})

export const DoubleRadioButtons = (props: Props) => {
  const { labels, isChecked, onPressFirst, onPressSecond } = props
  const [firstLabel, secondLabel] = labels
  return (
    <View style={styles.row}>
      <TouchableOpacity style={styles.touchableOpacityRow} onPress={onPressFirst}>
        <RadioButton checked={isChecked} style={styles.radioItem} />
        <Text style={styles.radioButtonLabel}>{firstLabel}</Text>
      </TouchableOpacity>
      <TouchableOpacity style={[styles.touchableOpacityRow, { marginLeft: SPACES.MP2 }]} onPress={onPressSecond}>
        <RadioButton checked={isChecked === undefined ? undefined : !isChecked} style={styles.radioItem} />
        <Text style={styles.radioButtonLabel}>{secondLabel}</Text>
      </TouchableOpacity>
    </View>
  )
}
