import { RegisterMedicationFollowUpUserInfo } from 'core/domain/userInfo'

const SET_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO = 'register-medication-follow-up/set' as const
const CLEAR_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO = 'register-medication-follow-up/clear' as const

const userInfo: RegisterMedicationFollowUpUserInfo | null = null
function createInitialState(): RegisterMedicationFollowUpUserInfo | null {
  return userInfo
}

export function setRegisterMedicationFollowUpUserInfo(result: RegisterMedicationFollowUpUserInfo) {
  return {
    type: SET_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO,
    payload: result,
  }
}

export function clearRegisterMedicationFollowUpUserInfo() {
  return {
    type: CLEAR_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO,
  }
}

type SetRegisterMedicationFollowUpUserInfo = ReturnType<typeof setRegisterMedicationFollowUpUserInfo>
type ClearRegisterMedicationFollowUpUserInfo = ReturnType<typeof clearRegisterMedicationFollowUpUserInfo>
type Action = SetRegisterMedicationFollowUpUserInfo | ClearRegisterMedicationFollowUpUserInfo

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO:
      return action.payload
    case CLEAR_REGISTER_MEDICATION_FOLLOW_UP_USER_INFO:
      return createInitialState()
    default:
      return state
  }
}
