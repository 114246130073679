import * as React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { CommonLabel } from 'components/common/atoms/CommonLabel'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import { ImageButton } from 'components/common/atoms/ImageButton'
import { Footer } from 'components/common/atoms/Footer'
import usage from 'assets/images/usage.png'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { ServiceMessageThumbnails } from 'components/common/molecules/ServiceMessageThumbnails'
import { AnnounceLabel } from 'components/common/atoms/AnnounceLabel'
import { useNavigateUtility } from 'lib/hooks'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: 'calc(100% - 100px)',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  registeredSection: {
    paddingHorizontal: SPACES.MP2,
  },
  spacer: {
    marginBottom: SPACES.MP2,
  },
  registeredTitle: {
    marginVertical: SPACES.MP2,
  },
  registeredAnnounce: {
    marginBottom: SPACES.MP3,
  },
  registeredImageArea: {
    width: '100%',
  },
  serviceMessageCaution: {
    marginTop: SPACES.MP2,
  },
  beginnersGuidesArea: {
    width: '100%',
  },
  beginnersGuidesButton: {
    backgroundColor: COLORS.SECONDARY,
  },
})

export default function Registered() {
  const { navigate } = useNavigateUtility()

  return (
    <View style={styles.background}>
      <ScrollView>
        <View style={styles.registeredSection}>
          <CommonTitle style={styles.registeredTitle} title={'処方せん送信が完了しました'} />
          <CommonLabel style={styles.spacer} title={'送信結果をLINEでお知らせします。'} />
          <AnnounceLabel
            size={'middle'}
            style={styles.registeredAnnounce}
            title={
              '・処方せんの有効期限内にお越しください。\n・お薬のお受け取りには、処方せん原本が必要です。\n・処方せん送信を取り消したい場合は店舗にお電話ください。\n・原本ご持参時にはLINEで送信した旨をお伝えください。'
            }
          />
          <View style={styles.registeredImageArea}>
            <CommonTitle title={'この後は…'} />
          </View>
          <View style={styles.spacer}>
            <ServiceMessageThumbnails
              subTitle={
                <AnnounceLabel
                  style={styles.serviceMessageCaution}
                  size={'middle'}
                  title={'※こちらのメッセージではまだ受付は完了しておりません'}
                />
              }
            />
          </View>
          <CommonLabel style={styles.spacer} title={'くわしくはこちらをご覧ください。'} />
          <View style={[styles.beginnersGuidesArea, styles.spacer]}>
            <ImageButton
              iconResource={usage}
              style={styles.beginnersGuidesButton}
              onPress={() => {
                navigate('/usage')
              }}
            />
          </View>
        </View>
      </ScrollView>
      <Footer>
        <SecondaryButton
          label="最初の画面に戻る"
          onPress={() => {
            navigate('/')
          }}
        />
      </Footer>
    </View>
  )
}
