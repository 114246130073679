import * as React from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import { SAFE_AREA } from 'constants/patterns'
import { TermsTextView } from 'components/common/TermsTextView'

/**
 * Styles
 */
const styles = StyleSheet.create({
  container: {
    height: '100%',
    padding: SPACES.MP2,
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
})

export default function Terms() {
  return (
    <View style={SAFE_AREA.BASE}>
      <ScrollView style={styles.container}>
        <TermsTextView />
      </ScrollView>
    </View>
  )
}
