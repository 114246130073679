import React from 'react'
import { ViewStyle, StyleSheet, Image, View, Text } from 'react-native'
import emptyIcon from 'assets/images/empty.svg'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Interface
 * @text : テキスト
 * @style : スタイル
 */
interface Props {
  text: string
  style?: ViewStyle
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  emptyWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: FONTS.TITLE.size,
    lineHeight: FONTS.TITLE.leading,
    fontWeight: 'bold',
    color: COLORS.ANNOTATION,
  },
  icon: {
    height: 40,
    width: 40,
    marginBottom: SPACES.MP1,
  },
})

export function EmptyPanel(props: Props) {
  const { text } = props
  return (
    <View style={[props.style, styles.emptyWrapper]}>
      <Image style={styles.icon} source={{ uri: emptyIcon }} />
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}
