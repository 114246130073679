import React, { useEffect, useRef, useState } from 'react'
import { ViewStyle } from 'react-native'
import ViewImageStyles from 'components/common/ViewImage/ViewImage.module.css'

/**
 * Interface
 * @dataUrl : テキスト
 * @style : スタイル
 */
interface Props {
  dataUrl: string | null
  style?: ViewStyle
}

/**
 * Interface
 * @width : canvas 幅
 * @height : canvas 高さ
 * @x : canvas 内の描画位置
 * @y : canvas 内の描画位置
 */
interface CanvasInfo {
  width: number
  height: number
  x: number
  y: number
}

export default function ViewImage(props: Props) {
  const { dataUrl } = props
  const canvasWrapperEl = useRef<HTMLDivElement>(null)
  const canvasEl = useRef<HTMLCanvasElement>(null)
  // canvas のサイズ情報や canvas 内の描画位置情報
  const [canvasInfo, setCanvasInfo] = useState<CanvasInfo>({ width: 0, height: 0, x: 0, y: 0 })
  // canvas の context
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)
  // 描画するサイズ変換した画像を生成したかのフラグ
  const [isCreatedImg, setIsCreatedImg] = useState(false)
  // 描画するサイズ変換した画像
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  /**
   * 表示する画像生成用
   */
  useEffect(() => {
    // img 生成後は処理しない
    if (isCreatedImg || !dataUrl) return
    // サムネイル描画
    const img = new Image()
    img.src = dataUrl
    img.onload = () => {
      const canvasWrapper = canvasWrapperEl.current
      const canvas = canvasEl.current
      if (!canvasWrapper || !canvas) return
      setCtx(canvas.getContext('2d'))
      // iOS 14.5 で height が正しく適用されない問題があったため、TS 側でサイズ指定
      const canvasWidth = canvasWrapper.clientWidth - 8 * 2 * 2
      const canvasHeight = canvasWrapper.clientHeight - 8 * 2 * 2
      canvas.width = canvasWidth
      canvas.height = canvasHeight
      // canvas の高さと画像の高さから割り出した比率を画像幅に掛け合わせた、canvas に入る画像サイズの算出
      const resizedImageWidth = img.width * (canvasHeight / img.height)
      const resizedImageHeight = img.height * (canvasWidth / img.width)
      // 画像の高さが画像の幅より大きく、かつ canvas 幅より canvas に入る画像サイズの幅が大きい場合は、
      // 幅がおさまらなくなるため else の処理を適応する
      if (img.height > img.width && canvasWidth > resizedImageWidth) {
        setCanvasInfo({
          ...canvasInfo,
          width: resizedImageWidth,
          height: canvasHeight,
          x: (canvasWidth - resizedImageWidth) / 2,
        })
      } else {
        setCanvasInfo({
          ...canvasInfo,
          width: canvasWidth,
          height: resizedImageHeight,
          y: (canvasHeight - resizedImageHeight) / 2,
        })
      }
      setImage(img)
      setIsCreatedImg(true)
    }
  }, [dataUrl, ctx, isCreatedImg, canvasInfo])

  /**
   * 表示する canvas の描画とコントラスト調整
   */
  useEffect(() => {
    if (!ctx || !image) return
    ctx.drawImage(image, canvasInfo.x, canvasInfo.y, canvasInfo.width, canvasInfo.height)
  }, [ctx, image, canvasInfo])

  if (!dataUrl) return null

  return (
    <div ref={canvasWrapperEl} className={ViewImageStyles.content}>
      <canvas ref={canvasEl} className={ViewImageStyles.canvas} />
    </div>
  )
}
