import React, { useEffect, useMemo, useRef } from 'react'
import { StyleSheet, Text, View, ScrollView } from 'react-native'
import { ShopInfo } from 'core/domain/shopInfo'
import { Prescription } from 'core/domain/prescription'
import { RegisterPrescriptionResult } from 'core/domain/registerPrescriptionResult'
import { AnnounceBar } from 'components/common/atoms/AnnounceBar'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { Footer } from 'components/common/atoms/Footer'
import * as SPACES from 'constants/spaces'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import { AnnounceLabel } from 'components/common/atoms/AnnounceLabel'
import { useNavigateUtility } from 'lib/hooks'

import { liff } from '@line/liff'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: 'calc(100% - 100px)',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  confirmSection: {
    paddingHorizontal: SPACES.MP2,
    marginBottom: SPACES.MP3,
  },
  sendConfirm: {
    marginTop: SPACES.MP3,
  },
  table: {
    marginTop: SPACES.MP2,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: COLORS.BORDER,
    backgroundColor: '#ffffff',
  },
  tableHeader: {
    minHeight: 64,
    minWidth: 106,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: SPACES.MP1,
  },
  tableBody: {
    minHeight: 64,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderLeftWidth: 1,
    borderColor: COLORS.BORDER,
  },
  tableHeaderText: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
    fontWeight: 'bold',
  },
  tableBodyText: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.TEXT,
    margin: SPACES.MP2,
  },
  lastRow: {
    borderBottomWidth: 1,
  },
  privacyPolicyAnnounce: {
    marginTop: SPACES.MP2,
    marginBottom: SPACES.MP1,
  },
})

/**
 * Interface
 * @prescription 処方せん情報
 */
interface Props {
  prescription: Prescription | null
  shopInfo: ShopInfo | null
  registerPrescription: RegisterPrescriptionResult | null
  network: number
  actions: {
    sendPostPrescription: (prescription: Prescription) => void
    setNetworkError: () => void
  }
}

type prescriptionKey = keyof Prescription
const preConfirms: { key: prescriptionKey; label: string }[] = [
  { key: 'hasPrescriptionNote', label: 'おくすり手帳をもってくる' },
  { key: 'isPregnancy', label: '妊娠中である' },
  { key: 'isBreastFeeding', label: '授乳中である' },
]

export default function PatientConfirm(props: Props) {
  const { prescription, shopInfo, registerPrescription, network, actions } = props
  const { navigate } = useNavigateUtility()
  const registeringPrescription = useRef(false)
  const preConfirmText = useMemo(() => {
    if (!prescription) return ''
    // 事前確認項目のチェック状態を洗い出し、文字列に変換
    return preConfirms
      .filter(({ key }) => prescription[key] === true)
      .map(({ label }) => label)
      .join('\n')
  }, [prescription])

  const isDisabled = useMemo(() => {
    return !prescription || registeringPrescription.current || network > 0
  }, [prescription, network, registeringPrescription])

  const tableItemList = [
    { header: '送信先', text: shopInfo?.name || '' },
    { header: '携帯電話番号', text: prescription?.tel || '' },
    { header: '事前確認', text: preConfirmText || '' },
    { header: '伝達事項', text: prescription?.allergy || '' },
  ]

  const getNotificationText = (item: { header: string; text: string }) => {
    if (item.header === '伝達事項') {
      return prescription?.needsNotification ? item.text : 'なし'
    }
    return item.text
  }

  // registerPrescription が null でなかったら、送信成功とみなして遷移する
  useEffect(() => {
    if (!registerPrescription) return
    navigate('/prescription-send/step-4')
  }, [registerPrescription, navigate])

  if (!prescription) return <></>

  return (
    <View style={styles.background}>
      <AnnounceBar message={'送信完了していません。内容をご確認ください。'} />
      <ScrollView>
        <View style={styles.confirmSection}>
          <CommonTitle style={styles.sendConfirm} title={'この内容で送信します'} />
          <AnnounceLabel
            style={styles.privacyPolicyAnnounce}
            title={'お薬のお受け取りには、\n処方せん原本が必要です。\n有効期限内にお持ちください。'}
            size={'middle'}
            isCenter
            isBold
          />
          <View style={styles.table}>
            {tableItemList.map((item, index) => (
              <View style={[styles.tableRow, tableItemList.length === index + 1 && styles.lastRow]} key={index}>
                <View style={styles.tableHeader}>
                  <Text style={styles.tableHeaderText}>{item.header}</Text>
                </View>
                <View style={styles.tableBody}>
                  <Text style={styles.tableBodyText}>{getNotificationText(item)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
      <Footer>
        <PrimaryButton
          label="送信する"
          disabled={isDisabled}
          onPress={async () => {
            if (!prescription || registeringPrescription.current === true) return
            registeringPrescription.current = true
            let patientName = ''
            // LIFF-SDK のネットワーク通信エラーを受け取るため try-catch を使用
            try {
              const liffProfile = await liff.getProfile()
              patientName = liffProfile && liffProfile.displayName
            } catch (error) {
              actions.setNetworkError()
              registeringPrescription.current = false
              return
            }
            const shopId = shopInfo ? String(shopInfo.id) : ''
            const newPrescription = { ...prescription, shopId, patientName }
            await actions.sendPostPrescription(newPrescription)
            registeringPrescription.current = false
          }}
        />
      </Footer>
    </View>
  )
}
