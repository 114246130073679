import { Dispatch } from 'redux'
import { isOnline } from 'core/domain/app'
import RecoverableError from 'core/domain/recoverableError'
import { clearError, setError } from 'core/modules/error'
import { beginCommunication, endCommunication } from 'core/modules/network'
import * as Auth from 'core/domain/auth'
import * as VALUES from 'constants/values'

const SERVER_ERROR_MESSAGE = '通信エラーです。時間をおいて再度お試しください。'
const RELOAD_LABEL = '再読み込み'

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
}

interface ApiErrorResponse {
  code: string
  message: string
  context: {
    message: string
  }
}

export type DidCallApiCallback<T1, T2> = (dispatch: Dispatch, data: T1, options?: Options<T2>) => void
export interface Options<T> {
  reload?: () => void
  navigate?: () => void
  payload?: T
}

function createError(apiErrorResponse: ApiErrorResponse) {
  if (apiErrorResponse.context && apiErrorResponse.context.message) {
    return new Error(apiErrorResponse.context.message)
  } else if (apiErrorResponse.message) {
    return new Error(apiErrorResponse.message)
  }
  return new Error(SERVER_ERROR_MESSAGE)
}

function isJsonResponse(response: Response): boolean {
  return response.headers.get('Content-Type')?.includes('application/json') ?? false
}

export function validateOnLine(dispatch: Dispatch) {
  if (isOnline()) {
    return true
  }
  dispatch(setError(new Error(VALUES.NETWORK_ERROR_MESSAGE)))
  return false
}

// ----- call GET/POST/PUT/DELETE Api -----
export async function callApi<T1, T2>(
  httpMethod: HttpMethod,
  apiName: string,
  body: string | null,
  dispatch: Dispatch,
  didCallApiCallback?: DidCallApiCallback<T1, T2>,
  options?: Options<T2>,
) {
  const apiUrl = `${process.env.REACT_APP_TOMODS_API_ENDPOINT}/${apiName}`

  try {
    dispatch(beginCommunication())
    dispatch(clearError())

    const accessToken = Auth.getAccessToken()

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const init = body
      ? {
          method: httpMethod,
          headers,
          body: body,
        }
      : {
          method: httpMethod,
          headers,
        }

    const response = await fetch(apiUrl, init)
    if (!response.ok) {
      if (!isJsonResponse(response)) {
        dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
        return
      }

      const apiErrorResponse: ApiErrorResponse = await response.json()
      if (!apiErrorResponse.code.startsWith('40')) {
        dispatch(setError(createError(apiErrorResponse)))
        return
      } else {
        dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
        return
      }
    }
    const data: T1 = await response.json()
    if (didCallApiCallback) {
      didCallApiCallback(dispatch, data, options)
    }
    return data
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (e: any) {
    if (options && options.reload) {
      dispatch(setError(new RecoverableError(VALUES.NETWORK_ERROR_MESSAGE, RELOAD_LABEL, options.reload)))
      return
    }

    if (!validateOnLine(dispatch)) {
      return
    }

    // サービスメッセージからアプリに遷移した場合、一次遷移の際に走る fetch() の最中に二次遷移の処理が発生し、
    // fetch() が中断されることにより例外エラーが発生し、エラーダイアログが表示されるのを回避するための処理
    // TODO: TypeError が他のエラーでも発生するかの検証が必要
    if (e.name === 'TypeError') {
      return
    }

    dispatch(setError(new Error(SERVER_ERROR_MESSAGE)))
  } finally {
    dispatch(endCommunication())
  }
}
