import React from 'react'
import { Outlet } from 'react-router-dom'

/**
 * Interface
 * @title : タイトル
 */
interface Props {
  title?: string
}

export default function RouteWrapper(props: Props) {
  // タイトルを更新
  if (props.title) {
    document.title = props.title
  }

  return <Outlet />
}
