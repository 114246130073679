import React from 'react'
import { Usecases } from 'core'
import { Prescription } from 'core/domain/prescription'
import { setError } from 'core/modules/error'
import PatientConfirm from 'components/screens/PrescriptionSend/PatientConfirm'
import * as VALUES from 'constants/values'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export default function PatientDetailContainer() {
  const dispatch = useAppDispatch()
  const actions = {
    async sendPostPrescription(prescription: Prescription) {
      await dispatch(Usecases.registerPrescription.sendPostPrescription(prescription))
    },
    setNetworkError() {
      dispatch(setError(new Error(VALUES.NETWORK_ERROR_MESSAGE)))
    },
  }

  const prescription = useAppSelector(state => state.prescriptionLatest)
  const shopInfo = useAppSelector(state => state.shopDetail)
  const registerPrescription = useAppSelector(state => state.registerPrescriptionResult)
  const network = useAppSelector(state => state.network)

  return (
    <PatientConfirm
      prescription={prescription}
      shopInfo={shopInfo}
      registerPrescription={registerPrescription}
      network={network}
      actions={actions}
    />
  )
}
