import { Dispatch } from 'redux'
import { beginCommunication, endCommunication } from 'core/modules/network'

export type DidGetGeolocationCallback = (latitude: number, longitude: number) => void
export type DidFailGetGeolocationCallback = (error: GeolocationPositionError) => void

export const getGeolocation = (
  dispatch: Dispatch,
  didGetGeolocation: DidGetGeolocationCallback,
  didFailGetGeolocation?: DidFailGetGeolocationCallback,
) => {
  dispatch(beginCommunication())

  navigator.geolocation.getCurrentPosition(
    position => {
      dispatch(endCommunication())
      didGetGeolocation(position.coords.latitude, position.coords.longitude)
    },
    error => {
      dispatch(endCommunication())
      didFailGetGeolocation && didFailGetGeolocation(error)
    },
    { timeout: 10000, maximumAge: 0 },
  )
}
