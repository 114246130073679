import React, { useMemo } from 'react'
import { ViewStyle, StyleSheet, Image, View, Text, TouchableOpacity } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'

/**
 * Interface
 * @label : ボタンのラベル
 * @size : 指定したサイズのボタンスタイルに変更
 * @labelSize : 指定したサイズのラベルスタイルに変更
 * @isIconRight : アイコンを右側に表示するかのフラグ
 * @iconResource : アイコン表示
 * @disabled : ボタンの非活性状態
 * @styles : スタイル
 * @onPress : タップイベント
 */
interface Props {
  label: string
  subLabel?: string
  size?: string
  labelSize?: string
  isIconRight?: boolean
  iconResource?: string
  disabled?: boolean
  style?: ViewStyle
  onPress?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  button: {
    height: 48,
    borderRadius: 4,
    border: `solid 1px ${COLORS.PRIMARY}`,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    paddingLeft: 8,
    paddingRight: 8,
  },
  small: {
    height: 32,
  },
  disabled: {
    opacity: 0.7,
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: 4,
  },
  rightIcon: {
    justifyContent: 'space-between',
  },
  label: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.PRIMARY,
  },
  smallLabel: {
    fontSize: FONTS.FOOTNOTE.size,
    lineHeight: FONTS.FOOTNOTE.leading,
  },
  xSmallLabel: {
    fontSize: FONTS.SMALL_FOOTNOTE.size,
    lineHeight: FONTS.SMALL_FOOTNOTE.leading,
  },
  empty: {
    width: 18,
  },
})

export function SecondaryButton(props: Props) {
  const { label, size, labelSize, isIconRight, iconResource, disabled } = props

  const sizeStyle = useMemo(() => {
    switch (size) {
      case 'small':
        return styles.small
      default:
        return
    }
  }, [size])

  const labelSizeStyle = useMemo(() => {
    switch (labelSize) {
      case 'small':
        return styles.smallLabel
      case 'xSmall':
        return styles.xSmallLabel
      default:
        return
    }
  }, [labelSize])

  return (
    <TouchableOpacity
      style={[styles.button, sizeStyle, disabled && styles.disabled, isIconRight && styles.rightIcon, props.style]}
      disabled={disabled}
      onPress={props.onPress}>
      {iconResource && !isIconRight && <Image style={styles.icon} source={{ uri: iconResource }} />}
      {/* アイコンを右側に表示する際に、space-between によるテキスト中央表示のため、アイコンと同じ幅の空要素を表示 */}
      {iconResource && isIconRight && <View style={styles.empty} />}
      <Text style={[styles.label, labelSizeStyle]}>{label}</Text>
      {iconResource && isIconRight && <Image style={styles.icon} source={{ uri: iconResource }} />}
    </TouchableOpacity>
  )
}
