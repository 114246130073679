import Resizer from 'react-image-file-resizer'

// 変換後の画像の最大長辺
const MAX_LONG_SIDE = 1024
// 変換時の画像画質
const IMAGE_QUALITY_RATIO = 70
export const resizeAndConvertFileToBase64 = (file: File, format: string): Promise<string> => {
  // デフォルトで 1024✕1024、70% に圧縮をかけていたため Resizer を使って変換をかける
  return new Promise((resolve, reject) => {
    if (file === undefined) return reject('no file')
    if (!file.type.match(format)) return reject('file type error')
    Resizer.imageFileResizer(
      file,
      MAX_LONG_SIDE,
      MAX_LONG_SIDE,
      'JPEG',
      IMAGE_QUALITY_RATIO,
      0,
      uri => {
        return resolve(uri as string)
      },
      'base64',
    )
  })
}
