import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { SecondaryButton } from 'components/common/atoms/SecondaryButton'
import ModalStyles from 'components/common/ConfirmModal/ConfirmModal.module.css'
import * as SPACES from 'constants/spaces'
import * as COLORS from 'constants/colors'

const styles = StyleSheet.create({
  modal: {
    maxWidth: 288,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    margin: 'auto',
  },
  contentWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    width: '100%',
  },
  titleWrapper: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.BORDER,
    padding: SPACES.MP2,
  },
  descriptionWrapper: {
    padding: SPACES.MP2,
    paddingBottom: SPACES.MP1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalFooter: {
    height: 80,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: COLORS.BORDER,
    padding: SPACES.MP2,
  },
  primaryButton: {
    width: `calc(50% - ${SPACES.MP1}px)`,
    marginLeft: SPACES.MP1,
  },
  SecondaryButton: {
    width: `calc(50% - ${SPACES.MP1}px)`,
    marginRight: SPACES.MP1,
  },
})

/**
 * Interface
 * @isOpened : ダイアログ表示
 * @title : タイトル
 * @description : 説明文
 * @url: URL
 * @doneLabel : 決定系ボタンのラベル
 * @cancelLabel : 中止系ボタンのラベル
 * @isDisabled : 非活性フラグ
 * @onDonePress : 決定系ボタンのタップイベント
 * @onCancelPress : 中止系ボタンのタップイベント
 */
interface Props {
  isOpened: boolean
  title?: string
  description: string
  url?: string
  doneLabel?: string
  cancelLabel?: string
  isDisabled?: boolean
  onPressDone: () => void
  onPressCancel?: () => void
}

export default function ConfirmModal(props: Props) {
  const { isOpened, title, description, url, doneLabel, cancelLabel, isDisabled } = props

  if (!isOpened) {
    return null
  }

  return (
    <div className={ModalStyles.modalContainer}>
      <div className={ModalStyles.modalWrapper}>
        <View style={styles.modal}>
          {title && (
            <View style={styles.titleWrapper}>
              <Text>{title}</Text>
            </View>
          )}
          <View style={styles.contentWrapper}>
            <View style={styles.content}>
              <View style={styles.descriptionWrapper}>
                <Text>{description}</Text>
              </View>
              {url && (
                <div className={ModalStyles.urlWrapper}>
                  <Text>{url}</Text>
                </div>
              )}
            </View>
          </View>
          <View style={styles.modalFooter}>
            {cancelLabel && (
              <SecondaryButton
                style={styles.SecondaryButton}
                label={cancelLabel}
                disabled={isDisabled}
                onPress={props.onPressCancel}
              />
            )}
            <PrimaryButton
              style={styles.primaryButton}
              label={doneLabel ? doneLabel : 'OK'}
              disabled={isDisabled}
              onPress={props.onPressDone}
            />
          </View>
        </View>
      </div>
    </div>
  )
}
