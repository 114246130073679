import React, { useMemo } from 'react'
import { ViewStyle, StyleSheet, View, Text } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'

/**
 * Interface
 * @title : タイトル
 * @size : 指定したサイズのラベルスタイルに変更
 * @styles : スタイル
 */
interface Props {
  title: string
  size?: 'large' | 'middle'
  style?: ViewStyle
  isCenter?: boolean
  isBold?: boolean
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  title: {
    fontSize: FONTS.CAPTION.size,
    lineHeight: FONTS.CAPTION.leading,
    color: COLORS.ALERT,
  },
  large: {
    fontSize: FONTS.HEADING.size,
    lineHeight: FONTS.HEADING.leading,
  },
  middle: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: COLORS.ALERT,
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
})

export function AnnounceLabel(props: Props) {
  const { title, size, isCenter, isBold } = props

  const sizeStyle = useMemo(() => {
    switch (size) {
      case 'large':
        return styles.large
      case 'middle':
        return styles.middle
      default:
        return
    }
  }, [size])

  const centerStyle = useMemo(() => (isCenter ? styles.center : undefined), [isCenter])
  const boldStyle = useMemo(() => (isBold ? styles.bold : undefined), [isBold])

  return (
    <View style={props.style}>
      <Text style={[styles.title, sizeStyle, centerStyle, boldStyle]}>{title}</Text>
    </View>
  )
}
