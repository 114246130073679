import React, { useMemo } from 'react'
import { ViewStyle, StyleSheet, Image, View, Text, TouchableOpacity } from 'react-native'
import * as COLORS from 'constants/colors'
import * as FONTS from 'constants/fonts'
import * as SPACES from 'constants/spaces'

/**
 * Interface
 * @label : ボタンのラベル
 * @subLabel : ボタンのサブラベル
 * @size : 指定したサイズのボタンスタイルに変更
 * @labelSize : 指定したサイズのラベルスタイルに変更
 * @imageResource : アイコン表示
 * @disabled : ボタンの非活性状態
 * @styles : スタイル
 * @onPress : タップイベント
 */
interface Props {
  label: string
  subLabel?: string
  size?: string
  labelSize?: string
  imageResource?: string
  imageSize?: string
  disabled?: boolean
  style?: ViewStyle
  onPress?: () => void
}

/**
 * Styles
 */
const styles = StyleSheet.create({
  button: {
    height: 48,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.PRIMARY,
  },
  large: {
    height: 84,
  },
  disabled: {
    opacity: 0.3,
  },
  image: {
    height: 18,
    width: 18,
    marginRight: SPACES.MP1,
  },
  largeImage: {
    height: 28,
    width: 28,
  },
  label: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    color: '#ffffff',
  },
  subLabel: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: FONTS.FOOTNOTE.size,
    lineHeight: FONTS.FOOTNOTE.leading,
    color: '#ffffff',
  },
  smallLabel: {
    fontSize: FONTS.FOOTNOTE.size,
    lineHeight: FONTS.FOOTNOTE.leading,
  },
  largeLabel: {
    fontSize: FONTS.HEADING.size,
    lineHeight: FONTS.HEADING.leading,
    fontWeight: 'bold',
  },
})

export function PrimaryButton(props: Props) {
  const { label, subLabel, size, labelSize, imageResource, imageSize, disabled } = props

  const sizeStyle = useMemo(() => {
    switch (size) {
      case 'large':
        return styles.large
      default:
        return
    }
  }, [size])

  const labelSizeStyle = useMemo(() => {
    switch (labelSize) {
      case 'small':
        return styles.smallLabel
      case 'large':
        return styles.largeLabel
      default:
        return
    }
  }, [labelSize])

  const imageSizeStyle = useMemo(() => {
    switch (imageSize) {
      case 'large':
        return styles.largeImage
      default:
        return
    }
  }, [imageSize])

  return (
    <TouchableOpacity
      style={[styles.button, sizeStyle, disabled && styles.disabled, props.style]}
      disabled={disabled}
      onPress={props.onPress}>
      {imageResource && <Image style={[styles.image, imageSizeStyle]} source={{ uri: imageResource }} />}
      <View>
        {subLabel && <Text style={styles.subLabel}>{subLabel}</Text>}
        <Text style={[styles.label, labelSizeStyle]}>{label}</Text>
      </View>
    </TouchableOpacity>
  )
}
