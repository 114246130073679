import React, { useEffect, useRef } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useNavigateUtility, useAppSelector, useAppDispatch } from 'lib/hooks'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as FONTS from 'constants/fonts'
import { Footer } from 'components/common/atoms/Footer'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { Usecases } from 'core'
import * as VALUES from 'constants/values'
import { setError } from 'core/modules/error'
import { SAFE_AREA } from 'constants/patterns'

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  container: {
    height: 'calc(100% - 80px)',
    padding: SPACES.MP2,
  },
  headerSection: {
    fontSize: FONTS.HEADING.size,
    lineHeight: FONTS.HEADING.leading,
    fontWeight: 'bold',
    color: COLORS.CPC,
  },
  confirmSection: {
    marginTop: SPACES.MP2,
    backgroundColor: `#FFFFFF`,
  },
  block: {
    flexDirection: 'row',
    height: 64,
  },
  columnSection: {
    width: '30%',
    paddingTop: 20,
    paddingLeft: SPACES.MP1,
    borderColor: COLORS.BORDER,
    borderWidth: 1,
  },
  columnText: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
    fontWeight: 'bold',
  },
  inputContentSection: {
    width: '70%',
    paddingTop: 20,
    paddingLeft: SPACES.MP2,
    borderColor: COLORS.BORDER,
    borderWidth: 1,
  },
  inputContentText: {
    fontSize: FONTS.BODY.size,
    lineHeight: FONTS.BODY.leading,
  },
})

export default function MedicationFollowUpRegisterConfirm() {
  const { navigateReplace } = useNavigateUtility()
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(state => state.medicationFollowUp)
  const shop = useAppSelector(state => state.shopDetail)
  const isFirstRender = useRef(false)
  if (!shop) navigateReplace(`/`)
  if (!userInfo?.name || !userInfo?.tel) navigateReplace(`/shop-detail/${shop?.id}`)

  // 電話番号の文字列をハイフンありに変換する(ex: 000-1111-2222)
  function getStringTel(str: string | undefined) {
    if (!str) return ''
    return str.slice(0, 3) + '-' + str.slice(3, 7) + '-' + str.slice(7, 11)
  }

  const sendPostRegisterMedicationFollowUp = async (shopId: string, name: string, tel: string) => {
    await dispatch(Usecases.registerMedicationFollowUp.sendPostPrescription(shopId, name, tel))
  }

  const result = useAppSelector(state => state.medicationFollowUpResult)
  useEffect(() => {
    if (isFirstRender.current && !result?.nothing) return navigateReplace('/medication-follow-up-register/done')
  }, [result, navigateReplace])

  const setNetworkError = () => {
    dispatch(setError(new Error(VALUES.NETWORK_ERROR_MESSAGE)))
  }

  // 初回レンダリング時の遷移防止
  useEffect(() => {
    isFirstRender.current = true
  }, [])

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <View style={styles.container}>
        <Text style={styles.headerSection}>{'この内容で送信しますか'}</Text>
        <View style={styles.confirmSection}>
          <View style={styles.block}>
            <View style={styles.columnSection}>
              <Text style={styles.columnText}>{'薬局名'}</Text>
            </View>
            <View style={styles.inputContentSection}>
              <Text style={styles.inputContentText}>{shop?.name}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View style={styles.columnSection}>
              <Text style={styles.columnText}>{'名前'}</Text>
            </View>
            <View style={styles.inputContentSection}>
              <Text style={styles.inputContentText}>{userInfo?.name}</Text>
            </View>
          </View>
          <View style={styles.block}>
            <View style={styles.columnSection}>
              <Text style={styles.columnText}>{'電話番号'}</Text>
            </View>
            <View style={styles.inputContentSection}>
              <Text style={styles.inputContentText}>{getStringTel(userInfo?.tel)}</Text>
            </View>
          </View>
        </View>
      </View>
      <Footer>
        <PrimaryButton
          label={'送信する'}
          disabled={false}
          onPress={async () => {
            if (!shop || !userInfo) return navigateReplace('/medication-follow-up/detail')
            try {
              await sendPostRegisterMedicationFollowUp(shop.id.toString(), userInfo.name, userInfo.tel)
            } catch (error) {
              setNetworkError()
              return
            }
          }}
        />
      </Footer>
    </View>
  )
}
