import { useState } from 'react'
import Portal from 'components/common/Portal'
import { ImageButton } from 'components/common/atoms/ImageButton'
import InvalidLineVersionModal from './InvalidLineVersionModal'
import homeAnnounceShortcut from 'assets/images/home_announce_shortcut.png'
import { isBeforeVersion } from 'utils/checkVersion'

import { liff } from '@line/liff'

const styles = {
  homeAnnounceButton: {
    height: 52,
  },
}

export default function HomeAnnounce() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const lineVersion = '13.20.0'
  return (
    <>
      <ImageButton
        iconResource={homeAnnounceShortcut}
        style={styles.homeAnnounceButton}
        onPress={() => {
          /** 検証のため、開発環境の場合は環境変数からLINEのバージョンを取得できる */
          const clientLineVersion =
            process.env.REACT_APP_STAGE === 'production'
              ? liff.getLineVersion()
              : process.env.REACT_APP_MOCK_LINE_VERSION ?? liff.getLineVersion()
          if (isBeforeVersion(clientLineVersion, lineVersion)) {
            return setIsOpenModal(true)
          }
          const context = liff.getContext()
          if (context === null) {
            throw new Error('invalid context.')
          }
          liff.createShortcutOnHomeScreen({
            url: context.endpointUrl,
          })
        }}
      />
      <Portal targetId={'modal'}>
        <InvalidLineVersionModal
          isOpened={isOpenModal}
          version={lineVersion}
          onPressDone={() => setIsOpenModal(false)}
        />
      </Portal>
    </>
  )
}
