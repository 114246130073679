import React from 'react'
import { setPrescriptionLatest } from 'core/modules/prescriptionLatest'
import { setPrescriptionPeriod } from 'core/modules/prescriptionPeriod'
import { Prescription } from 'core/domain/prescription'
import PrescriptionSelect from 'components/screens/PrescriptionSend/PrescriptionSelect'
import { useAppDispatch, useAppSelector } from 'lib/hooks'

export default function PrescriptionSelectContainer() {
  const dispatch = useAppDispatch()
  const actions = {
    setPrescriptionLatest(prescription: Prescription) {
      dispatch(setPrescriptionLatest(prescription))
    },
    setPrescriptionPeriod(isValidPeriod: boolean) {
      dispatch(setPrescriptionPeriod({ isValidPeriod: isValidPeriod }))
    },
  }

  const prescription = useAppSelector(state => state.prescriptionLatest)
  const isValidPeriod = useAppSelector(state => state.prescriptionPeriod.isValidPeriod)

  return <PrescriptionSelect prescription={prescription} actions={actions} isValidPeriod={isValidPeriod} />
}
