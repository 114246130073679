import * as React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import spinner from 'assets/images/spinner.svg'

/**
 * Styles
 */
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20,
  },
  spinner: {
    width: 30,
    height: 30,
  },
})

/**
 * Interface
 * @needsToShow : ローディングの表示可否
 */
interface Props {
  needsToShow: boolean
}

export default function LoadingPanel(props: Props) {
  if (!props.needsToShow) {
    return null
  }

  return (
    <View style={styles.container}>
      <Image style={styles.spinner} source={{ uri: spinner }} />
    </View>
  )
}
