import { Dispatch } from 'redux'
import * as Api from 'lib/api'
import { Prescription } from 'core/domain/prescription'
import { setPrescriptionLatest } from 'core/modules/prescriptionLatest'

function didCallGetPrescriptionLatestApi(dispatch: Dispatch, data: Prescription) {
  dispatch(setPrescriptionLatest(data))
}

export function sendGetPrescriptionLatest() {
  return async (dispatch: Dispatch) => {
    // Send GET prescriptions/last-input
    await Api.callApi(Api.HttpMethod.get, 'prescriptions/last-input', null, dispatch, didCallGetPrescriptionLatestApi)
  }
}
