import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { ScrollView, StyleSheet, View, Text, TextInput } from 'react-native'
import { MedicineDesignation, Prescription, PrescriptionPreConfirmChecks } from 'core/domain/prescription'
import { AnnounceBar } from 'components/common/atoms/AnnounceBar'
import { CommonTitle } from 'components/common/atoms/CommonTitle'
import { CommonLabel } from 'components/common/atoms/CommonLabel'
import { CheckItem } from 'components/common/molecules/CheckItem'
import { AnnounceLabel } from 'components/common/atoms/AnnounceLabel'
import { Footer } from 'components/common/atoms/Footer'
import { PrimaryButton } from 'components/common/atoms/PrimaryButton'
import { TermsTextView } from 'components/common/TermsTextView'
import * as COLORS from 'constants/colors'
import * as SPACES from 'constants/spaces'
import * as VALUES from 'constants/values'
import { TEXT_INPUT } from 'constants/patterns'
import { useNavigateUtility } from 'lib/hooks'
import { DoubleRadioButtons } from 'components/common/molecules/DoubleRadioButtons'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: 'calc(100% - 100px)',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
  preConfirmSection: {
    paddingHorizontal: SPACES.MP2,
  },
  preConfirmTitle: {
    marginTop: SPACES.MP2,
  },
  preConfirmDescription: {
    marginVertical: SPACES.MP2,
  },
  checkItemList: {
    borderBottomWidth: 0,
  },
  inputSection: {
    paddingHorizontal: SPACES.MP2,
  },
  allergyInput: {
    marginTop: SPACES.MP2,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  telTitle: {
    marginTop: SPACES.MP4,
  },
  telInput: {
    marginTop: SPACES.MP2,
    marginBottom: SPACES.MP2,
  },
  errorTelInput: {
    marginBottom: 0,
  },
  privacyPolicyTitle: {
    marginTop: SPACES.MP2,
  },
  privacyPolicyAreaWrapper: {
    height: 100,
    borderWidth: 1,
    borderColor: COLORS.BORDER,
    marginTop: SPACES.MP2,
    marginBottom: SPACES.MP3,
  },
  privacyPolicyArea: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    paddingVertical: SPACES.MP1,
    paddingHorizontal: SPACES.MP1,
  },
  privacyPolicyAnnounce: {
    marginTop: SPACES.MP2,
    marginBottom: SPACES.MP1,
  },
  weightInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

/**
 * Interface
 * @prescription 処方せん情報
 */
interface Props {
  prescription: Prescription | null
  actions: {
    setPrescriptionLatest: (prescription: Prescription) => void
  }
}

type prescriptionKey = keyof Prescription
type prescriptionPreConfirmChecksKey = keyof PrescriptionPreConfirmChecks
const preConfirms: { key: prescriptionPreConfirmChecksKey; label: string }[] = [
  { key: 'hasPrescriptionNote', label: 'おくすり手帳をもってくる' },
  { key: 'isPregnancy', label: '妊娠中である' },
  { key: 'isBreastFeeding', label: '授乳中である' },
]
const defaultAllergy: { [key in prescriptionKey]?: string } = { allergy: '' }
const defaultTel: { [key in prescriptionKey]?: string } = { tel: '' }

export default function PatientDetail(props: Props) {
  const { prescription, actions } = props

  const { navigate } = useNavigateUtility()
  const allergyInputPlaceholder = '例）卵、花粉、ロキソプロフェン'
  const telInputPlaceholder = '例）09001234567'
  const [isTelInputError, setIsTelInputError] = useState<boolean>(false)
  const [isWeightInputError, setIsWeightInputError] = useState<boolean>(false)
  const [isRequiredError, setIsRequiredError] = useState<boolean>(false)
  const [isAllergyInputError, setIsAllergyInputError] = useState<boolean>(false)
  const isOriginalMedicineDesignation = (medicineDesignation: MedicineDesignation | undefined) => {
    if (medicineDesignation === undefined) return undefined
    return medicineDesignation === 'Original'
  }

  // 電話番号のバリデーションチェック
  const validateTelValue = useCallback((value: string): boolean => {
    const regexp = RegExp(`^[0-9]{${VALUES.TEL_INPUT_LIMIT}}$`)
    return regexp.test(value)
  }, [])

  // 体重のバリデーションチェック
  const validateWeightValue = useCallback((value: string): boolean => {
    const regexp = RegExp(/^([1-9]\d*|0)(\.\d+)?$/)
    return regexp.test(value) || value === undefined
  }, [])

  // 必須の項目がundefinedではないかのバリデーションチェック
  const validateRequiredValue = useCallback((p: Prescription): boolean => {
    return p.isVisitToday !== undefined && p.medicineDesignation !== undefined && p.needsNotification !== undefined
  }, [])

  // needsNotificationがtrueなのに、allergyが空の場合のバリデーションチェック
  const validateAllergyValue = useCallback((p: Prescription): boolean => {
    return !p.needsNotification || p.allergy !== ''
  }, [])

  // 前回処方せん入力値がない場合のケア
  useEffect(() => {
    if (!prescription) return // nullの時はリターンする
    // 既に初期処理が済んでいる
    //   or 元から prescription に当画面でセットするデータが存在する場合は処理しない)
    if (prescription.hasOwnProperty(preConfirms[0].key)) return
    // 事前確認事項の初期状態生成
    const defaultPreConfirms: { [key in prescriptionPreConfirmChecksKey]?: boolean } = {}
    preConfirms.forEach(preConfirm => {
      defaultPreConfirms[preConfirm.key] = false
    })
    const newPrescription: Prescription = {
      ...defaultPreConfirms,
      ...defaultAllergy,
      ...defaultTel,
      // prescription の状態を優先
      ...prescription,
    }

    // 初期状態のprescriptionLatestをセットする処理
    actions.setPrescriptionLatest(newPrescription)
  }, [prescription, actions])

  const checkItemList = useMemo(() => {
    if (!prescription || !prescription.hasOwnProperty(preConfirms[0].key)) return []
    return preConfirms.map(preConfirm => {
      const prescriptionKey = prescription?.[preConfirm.key]
      const isChecked: boolean = typeof prescriptionKey === 'boolean' ? prescriptionKey : false
      return {
        key: preConfirm.key,
        label: preConfirm.label,
        isChecked,
      }
    })
  }, [prescription])

  if (!prescription || !prescription.hasOwnProperty(preConfirms[0].key)) return <></>

  return (
    <View style={styles.background}>
      <AnnounceBar message={'予約完了していません。引き続き入力してください。'} />
      <ScrollView>
        <AnnounceLabel
          style={styles.privacyPolicyAnnounce}
          title={
            'お薬のお受け取りには、\n処方せん原本が必要です。\n有効期限内にお持ちください。\n原本ご持参時にはLINEで送信した旨をお伝えください。'
          }
          size={'large'}
          isBold
          isCenter
        />
        {/* ご来客予定 */}
        <View style={[styles.inputSection, { marginTop: SPACES.MP2 }]}>
          <View style={styles.titleWrapper}>
            <Text style={TEXT_INPUT.TITLE}>{'ご来局予定'}</Text>
            <AnnounceLabel title={'※必須'} />
          </View>
          <DoubleRadioButtons
            labels={['本日', '明日以降']}
            isChecked={prescription.isVisitToday}
            onPressFirst={() => {
              actions.setPrescriptionLatest({ ...prescription, isVisitToday: true })
            }}
            onPressSecond={() => {
              actions.setPrescriptionLatest({ ...prescription, isVisitToday: false })
            }}
          />
          {isRequiredError && prescription.isVisitToday === undefined && <AnnounceLabel title={`この項目は必須です`} />}
        </View>
        {/* 先発薬／ジェネリック医薬品 */}
        <View style={[styles.inputSection, { marginTop: SPACES.MP2 }]}>
          <View style={styles.titleWrapper}>
            <Text style={TEXT_INPUT.TITLE}>{'先発薬／ジェネリック医薬品'}</Text>
            <AnnounceLabel title={'※必須'} />
          </View>
          <DoubleRadioButtons
            labels={['先発薬', 'ジェネリック医薬品']}
            isChecked={isOriginalMedicineDesignation(prescription.medicineDesignation)}
            onPressFirst={() => {
              actions.setPrescriptionLatest({ ...prescription, medicineDesignation: 'Original' })
            }}
            onPressSecond={() => {
              actions.setPrescriptionLatest({ ...prescription, medicineDesignation: 'Generic' })
            }}
          />
          {isRequiredError && prescription.medicineDesignation === undefined && (
            <AnnounceLabel title={`この項目は必須です`} />
          )}
        </View>

        <View style={styles.preConfirmSection}>
          <CommonTitle style={styles.preConfirmTitle} title={'事前確認'} />
          <CommonLabel style={styles.preConfirmDescription} title={'あてはまる項目を選択してください'} />
        </View>
        <View>
          {checkItemList.map((checkItem, index) => (
            <CheckItem
              key={index}
              style={checkItemList.length !== index + 1 ? styles.checkItemList : undefined}
              checked={checkItem.isChecked}
              label={checkItem.label}
              onPress={() => {
                const nextKeyIsChecked: { [key in prescriptionPreConfirmChecksKey]?: boolean } = {}
                nextKeyIsChecked[checkItem.key] = !checkItem.isChecked
                actions.setPrescriptionLatest({ ...prescription, ...nextKeyIsChecked })
              }}
            />
          ))}
        </View>
        {/* 体重 */}
        <View style={styles.inputSection}>
          <CommonTitle style={styles.telTitle} title={'体重(15歳未満の方)'} />
          <View style={styles.allergyInput}>
            <View style={styles.weightInput}>
              <TextInput
                style={[TEXT_INPUT.SHORT, isWeightInputError && TEXT_INPUT.ERROR]}
                textContentType="none"
                value={String(prescription.weight ?? '')}
                keyboardType="default"
                placeholder="お子様の体重を記入ください"
                placeholderTextColor={COLORS.PLACEHOLDER}
                autoCapitalize="none"
                autoCorrect={false}
                clearButtonMode="unless-editing"
                maxLength={100}
                onChangeText={(value: string) => {
                  actions.setPrescriptionLatest({ ...prescription, weight: value })
                }}
              />
              <Text style={[TEXT_INPUT.TITLE, { marginLeft: SPACES.MP2 }]}>kg</Text>
            </View>
            {isWeightInputError && <AnnounceLabel title={`半角数字で入力してください`} />}
          </View>

          {/* 薬局伝達情報 */}
          <View style={[styles.allergyInput, { marginTop: SPACES.MP2 }]}>
            <View style={styles.titleWrapper}>
              <Text style={TEXT_INPUT.TITLE}>{'薬局への伝達情報'}</Text>
              <AnnounceLabel title={'※必須'} />
            </View>
            <DoubleRadioButtons
              labels={['あり', 'なし']}
              isChecked={prescription.needsNotification}
              onPressFirst={() => {
                actions.setPrescriptionLatest({ ...prescription, needsNotification: true })
              }}
              onPressSecond={() => {
                actions.setPrescriptionLatest({ ...prescription, needsNotification: false })
              }}
            />
            {isRequiredError && prescription.needsNotification === undefined && (
              <AnnounceLabel title={`この項目は必須です`} />
            )}
            <TextInput
              style={[
                { marginTop: SPACES.MP1 },
                TEXT_INPUT.BASE,
                isAllergyInputError && TEXT_INPUT.ERROR,
                !prescription.needsNotification && TEXT_INPUT.DISABLED,
              ]}
              textContentType="none"
              value={prescription.allergy || ''}
              placeholder={allergyInputPlaceholder}
              placeholderTextColor={COLORS.PLACEHOLDER}
              keyboardType="default"
              autoCapitalize="none"
              autoCorrect={false}
              aria-disabled={!prescription.needsNotification}
              clearButtonMode="unless-editing"
              maxLength={100}
              onChangeText={(value: string) => actions.setPrescriptionLatest({ ...prescription, allergy: value })}
            />
            {isAllergyInputError && <AnnounceLabel title={`伝達事項を入力してください`} />}
          </View>
          {/* 緊急連絡先の入力 */}
          <CommonTitle style={styles.telTitle} title={'緊急連絡先の入力'} />
          <View style={[styles.telInput, isTelInputError && styles.errorTelInput]}>
            <View style={styles.titleWrapper}>
              <Text style={TEXT_INPUT.TITLE}>{'携帯電話番号（半角ハイフン無し）'}</Text>
              <AnnounceLabel title={'※必須'} />
            </View>
            <TextInput
              style={[TEXT_INPUT.BASE, isTelInputError && TEXT_INPUT.ERROR]}
              textContentType="telephoneNumber"
              value={prescription.tel || ''}
              placeholder={telInputPlaceholder}
              placeholderTextColor={COLORS.PLACEHOLDER}
              keyboardType="phone-pad"
              autoCapitalize="none"
              autoCorrect={false}
              clearButtonMode="unless-editing"
              maxLength={VALUES.TEL_INPUT_LIMIT}
              onChangeText={(value: string) => actions.setPrescriptionLatest({ ...prescription, tel: value })}
            />
            {isTelInputError && <AnnounceLabel title={`半角数字の${VALUES.TEL_INPUT_LIMIT}桁で入力してください`} />}
          </View>
          <CommonTitle style={styles.privacyPolicyTitle} title={'利用規約を必ずお読みください'} />
          <ScrollView style={styles.privacyPolicyAreaWrapper}>
            <View style={styles.privacyPolicyArea}>
              <TermsTextView />
            </View>
          </ScrollView>
        </View>
      </ScrollView>
      <Footer size={'large'}>
        <PrimaryButton
          label={'処方せんを送信する'}
          subLabel={'利用規約に同意して'}
          size={'large'}
          labelSize={'large'}
          disabled={!prescription}
          onPress={() => {
            // もしも、needsNotificationがfalseの場合、allergyの値を空にする
            if (!prescription.needsNotification) {
              actions.setPrescriptionLatest({ ...prescription, allergy: '' })
            }
            const validateTelResult = validateTelValue(prescription.tel)
            const validateWeightResult = validateWeightValue(prescription.weight)
            const validateRequiredValueResult = validateRequiredValue(prescription)
            const validateAllergyResult = validateAllergyValue(prescription)
            setIsTelInputError(!validateTelResult)
            setIsWeightInputError(!validateWeightResult)
            setIsRequiredError(!validateRequiredValueResult)
            setIsAllergyInputError(!validateAllergyResult)
            if (!validateTelResult || !validateWeightResult || !validateRequiredValueResult || !validateAllergyResult)
              return
            navigate('/prescription-send/step-3')
          }}
        />
      </Footer>
    </View>
  )
}
