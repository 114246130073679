import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigateUtility } from 'lib/hooks'
import { Outlet, useLocation } from 'react-router-dom'
import { Stepper } from 'components/common/molecules/Stepper'
import { RegisterPrescriptionResult } from 'core/domain/registerPrescriptionResult'
import * as COLORS from 'constants/colors'
import { SAFE_AREA } from 'constants/patterns'

/**
 * Styles
 */
const styles = StyleSheet.create({
  background: {
    height: '100%',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
  },
})

/**
 * Interface
 * @shop 店舗情報
 */
interface Props {
  registerPrescriptionResult: RegisterPrescriptionResult | null
}

export default function PrescriptionSend(props: Props) {
  const { registerPrescriptionResult } = props

  const { navigate } = useNavigateUtility()

  // 現在何番目の Step かを保持
  const [currentStep, setCurrentStep] = useState(1)
  const location = useLocation()

  useEffect(() => {
    const nextCurrentStep = parseInt(location.pathname.split('step-')[1], 10)
    if (nextCurrentStep === currentStep) return

    // 処方せん送信結果があり、かつ Step を戻ろうとしている場合はトップページに飛ばす
    if (registerPrescriptionResult !== null && nextCurrentStep < currentStep) {
      navigate('/')
      return
    }

    setCurrentStep(nextCurrentStep)
  }, [location, currentStep, navigate, registerPrescriptionResult])

  return (
    <View style={[styles.background, SAFE_AREA.BASE]}>
      <Stepper currentStep={currentStep} />
      <Outlet />
    </View>
  )
}
