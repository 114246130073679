import { ShopInfo } from 'core/domain/shopInfo'

const initialState: ShopInfo | null = null

export function createInitialState(): ShopInfo | null {
  return initialState
}

export const SET_SHOP_DETAIL = 'shop-detail/set' as const
export const CLEAR_SHOP_DETAIL = 'shop-detail/clear' as const

export function setShopDetail(shopDetail: ShopInfo) {
  return {
    type: SET_SHOP_DETAIL,
    payload: {
      shopDetail,
    },
  }
}

export function clearShopDetail() {
  return {
    type: CLEAR_SHOP_DETAIL,
  }
}

type SetShopDetailAction = ReturnType<typeof setShopDetail>
type ClearShopDetailAction = ReturnType<typeof clearShopDetail>
type Action = SetShopDetailAction | ClearShopDetailAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_SHOP_DETAIL:
      return action.payload.shopDetail
    case CLEAR_SHOP_DETAIL:
      return initialState
    default:
      return state
  }
}
